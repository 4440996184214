export function countdown (date) {
    if (date) {
        date = new Date(date);
        // Update the count down every 1 second
        const updateCountdown = setInterval(function () {
            const countdownElement = document.getElementById("countdown");

            // If countdown element is not found, delete the interval
            if (countdownElement === null) 
                clearInterval(updateCountdown);

            // Get today's date and time
            const now = new Date().getTime();

            // Find the distance between now and the count down date
            const distance = date - now;

            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element with id="countdown"
            countdownElement.innerHTML = `${days}d ${hours}h ${minutes}m ${seconds}s`;

            // If the count down is finished, do not display
            if (distance < 0) {
                clearInterval(updateCountdown);
                countdownElement.innerHTML = "";
            }
        }, 1000);   
    }
}