<template>
  <main class="main bg-white">
    <div class="em-l-container">
      <div class="em-c-container__body">
        <section class="em-c-section section">
          <div class="em-c-page-header">
            <h1 class="em-c-page-header__title title">Program</h1>
            <p class="em-c-page-header__desc em-u-font-style-semibold" style="color: #ad1723">
              The materials, presentations and video recordings, in UNITE
              website are intended for ExxonMobil internal use only. Do not
              distribute or publish publicly without a proper authorization from
              original owner.
            </p>
            <p class="em-c-page-header__desc">
              Watch the rocordings for Keynotes and Showcases below.
              <a class="em-c-text-link" v-on:click="registerWorkshops()" v-on:click.middle="registerWorkshops()"><span
                  class="em-u-font-style-semibold">Search or filter events</span></a>
              to easily find the event you're looking for.
            </p>
          </div>
          
          <SessionFilters 
            :filters="filters" 
            :tracksFilterOptions="tracksFilterOptions" 
            :datesFilterOptions="datesFilterOptions" 
            :sessionList="originalSessionsConfiguration"
            @update-items="updateList"
          ></SessionFilters>

          <div class="section-padding" />

          <!-- Events List -->
          <div v-for="(session, index) in sessionsConfiguration" v-bind:key="session.Id" v-bind:s="session"
            v-bind:index="index" class="event-list">
            <SessionCard 
              :session="session" 
              :filters="filters"
              :tracksFilterOptions="tracksFilterOptions"
              :datesFilterOptions="datesFilterOptions"
            ></SessionCard>
          </div>

          <!-- No Results -->
          <div v-if="sessionsConfiguration === undefined || sessionsConfiguration.length === 0" class="filter-error">
            <div>
              <p class="em-c-page-header__desc">
                <span class="em-u-font-style-semibold filter-red">No sessions match this criteria:</span>
                Please update your search parameters or
                <a class="em-c-text-link" v-on:click="resetFilters()">clear existing filters</a>
                to view more results.
              </p>
            </div>
          </div>

          <!-- Call for Submissions -->
          <hr class="em-u-width-100 em-u-margin-top-double em-u-margin-bottom-double" />
          <!--
          <h3 class="em-c-page-header__title title">
            Interested in adding a new event?
          </h3>
          <div>
            <p class="em-c-page-header__desc">
              We're now fully booked in all time zones. But we're still looking
              for volunteers to help out with hosting sessions, music, video
              recording, and more. Please contact
              <a
                class="em-c-text-link"
                v-bind:href="
                  'mailto:leonardo.l.gargioni@exxonmobil.com?subject=UNITE2022'
                "
              >
                Leonardo Gargioni
              </a>
              for more information.
            </p>
          </div>
          -->
        </section>
      </div>
    </div>
    <a id="scrollTop"><i class="icon-chevron-up"></i></a>
  </main>
</template>

<script setup>
import SharePointService from "../services/SharePointService";
import { onMounted, ref } from "vue";
import { defaultFilters } from '../utils/SessionsFilters';
import SessionCard from '../components/SessionCard.vue';
import SessionFilters from "../components/SessionFilters.vue";
import { createDatesFilter, createTracksFilter, createYearsFilter } from "../utils/FilterUtils";
import { setupProgram } from "../utils/SessionUtils";
import { useRoute } from "vue-router/composables";

let urlParams = useRoute().query;

const sessionsConfiguration = ref([]);
const originalSessionsConfiguration = ref([]);
const profilesConfiguration = ref([]);
const tracksFilterOptions = ref([]);
const datesFilterOptions = ref([]);
const yearsFilterOptions = ref([]);

const filters = ref(defaultFilters);

const loadPage = async () => {
  let config_sessions;

  // Filtering by year from props
  if (urlParams.year) {
    config_sessions = await SharePointService.getWithFilter("UniteProgramList", `?$filter=Year eq ${urlParams.year}`);
  }
  else {
    config_sessions = await SharePointService.get("UniteProgramList");
  }

  config_sessions = config_sessions.filter((item) => item.Active); // Grab only active programs
  
  let config_profiles = await SharePointService.get("ProfileConfiguration");
  config_profiles = config_profiles.filter((item) => item.Active); // Grab only active profiles 

  profilesConfiguration.value = config_profiles;

  console.log("Programs Configuration: ", config_sessions); // TO-DO: Delete this line
  console.log("Profiles Configuration: ", config_profiles); // TO-DO: Delete this line

  // Process the dates and times of the sessions
  if (config_sessions && config_sessions.length > 0) {
    for (let i = 0; i < config_sessions.length; i++) {
      await setupProgram(config_sessions[i], config_profiles);
    }
  }

  tracksFilterOptions.value = createTracksFilter(config_sessions);
  datesFilterOptions.value = createDatesFilter(config_sessions);
  yearsFilterOptions.value = createYearsFilter(config_sessions);      

  // Filter program with the filters selected
  // config_sessions = filteredList(filters.value, config_sessions, 
  //   datesFilterOptions.value, tracksFilterOptions.value, yearsFilterOptions.value); 

  sessionsConfiguration.value = config_sessions; 
  originalSessionsConfiguration.value = [...config_sessions];
}

onMounted(async () => {
  await loadPage().then(() => 
    console.log(sessionsConfiguration.value)
  );
});

const updateList = (list) => {
  sessionsConfiguration.value = list;
}
// export default {
//   props: ["profileList", "sessionList"],
//   data() {
//     return {
//       filterOpen: true,
//       regionsOpen: false,
//       tracksOpen: false,
//       datesOpen: false,
//       searchFilter: "",
//       AMselected: true,
//       APselected: true,
//       EUselected: true,
//       AFselected: true,
//       keynoteSelected: true,
//       panelSelected: true,
//       showcaseSelected: true,
//       copSelected: true,
//       datesFiltered: false,
//       tracksFiltered: false,
//       datesText: "",
//       tracksText: "",
//     };
//   },
//   async created() {
//     this.loadPage();
//   },
//   async mounted() {
//     this.vuenity();
//   },
//   async beforeDestroy() {
//     window.removeEventListener("popstate", this.onPop);
//   },
//   methods: {
//     loadPage() {
//       this.createDatesFilter();
//       this.updateDatesFilterOptionsText();
//       this.createTracksFilter();
//       this.updateTracksFilterOptionsText();
//     },
//     getSessionLink(ID) {
//       let windowHref = window.location.origin;
//       return windowHref + "/#/sessions/" + ID;
//     },
//     //start:shared
//     calendarClick(link) {
//       window.open(link);
//     },
//     //end:shared
//     //start:sessions
//     async shareSession(session) {
//       const copiedValue = this.getSessionLink(session.ID);
//       await navigator.clipboard.writeText(copiedValue);
//       session.isLinkDisplayed = true;
//     },
//     closeSessionLink(session) {
//       session.isLinkDisplayed = false;
//     },
//     registerWorkshops() {
//       this.resetFilters();
//       this.filterOpen = true;
//       document.getElementById("EventsList").scrollIntoView({
//         behavior: "smooth",
//       });
//     },
//     //end:sessions
//     //start:filter
//     createTracksFilter() {
//       this.tracksFilterOptions = new Array();
//       this.sessionList.forEach((session) => {
//         session.Tracks.forEach((track) => {
//           if (
//             !this.tracksFilterOptions.find(
//               (trackFilter) => trackFilter.name === track
//             )
//           ) {
//             this.tracksFilterOptions.push({
//               name: track,
//               selected: true,
//             });
//           }
//         });
//       });
//       this.tracksFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1));
//     },
//     triggerUpdateTracksFilterOptions() {
//       this.tracksFiltered = this.tracksFilterOptions.some(
//         (track) => !track.selected
//       );
//       this.updateTracksFilterOptionsText();
//     },
//     updateTracksFilterOptionsText() {
//       const numberOfSelectedTracks = this.tracksFilterOptions.filter(
//         (track) => track.selected
//       ).length;
//       const tracksText =
//         numberOfSelectedTracks === this.tracksFilterOptions.length
//           ? "All"
//           : numberOfSelectedTracks;
//       this.tracksText = "Tracks (" + tracksText + ")";
//     },
//     isSessionTrackInFilter(track) {
//       return this.tracksFilterOptions.some(
//         (trackFilter) => trackFilter.name === track && trackFilter.selected
//       );
//     },
//     applyTrackFilter(track) {
//       this.tracksFilterOptions.forEach((trackFilter) => {
//         if (trackFilter.name === track) {
//           trackFilter.selected = !trackFilter.selected;
//         }
//       });
//       this.triggerUpdateTracksFilterOptions();
//     },
//     createDatesFilter() {
//       this.datesFilterOptions = new Array();
//       this.sessionList.forEach((session) => {
//         const filter = session.LocalDateTime.Filter;
//         if (!this.datesFilterOptions.find((date) => date.Date === filter)) {
//           this.datesFilterOptions.push({
//             Date: filter,
//             Selected: true,
//           });
//         }
//       });
//     },
//     triggerUpdateDatesFilterOptions() {
//       this.datesFiltered = this.datesFilterOptions.some(
//         (dateFilter) => !dateFilter.Selected
//       );
//       this.updateDatesFilterOptionsText();
//     },
//     updateDatesFilterOptionsText() {
//       const numberOfSelectedDates = this.datesFilterOptions.filter(
//         (date) => date.Selected
//       ).length;
//       const dateText =
//         numberOfSelectedDates === this.datesFilterOptions.length
//           ? "All"
//           : numberOfSelectedDates;
//       this.datesText = "Dates (" + dateText + ")";
//     },
//     resetFilters() {
//       this.clearSearch();
//       this.resetRegionsFilter();
//       this.resetTypesFilter();
//       this.resetDatesFilter();
//       this.resetTracksFilter();
//       this.filterOpen = false;
//     },
//     clearSearch() {
//       this.searchFilter = "";
//     },
//     clearFilters() {
//       this.AMselected = false;
//       this.APselected = false;
//       this.EUselected = false;
//       this.AFselected = false;
//       this.keynoteSelected = false;
//       this.panelSelected = false;
//       this.showcaseSelected = false;
//       this.copSelected = false;
//       this.datesFilterOptions.forEach((date) => (date.Selected = false));
//       this.updateDatesFilterOptionsText();
//     },
//     selectAllTracks() {
//       this.tracksFilterOptions.forEach((track) => (track.selected = true));
//       this.updateTracksFilterOptionsText();
//     },
//     unselectAllTracks() {
//       this.tracksFilterOptions.forEach((track) => (track.selected = false));
//       this.updateTracksFilterOptionsText();
//     },
//     resetRegionsFilter() {
//       this.AMselected = true;
//       this.APselected = true;
//       this.EUselected = true;
//       this.AFselected = true;
//       this.regionsOpen = false;
//     },
//     resetTypesFilter() {
//       this.keynoteSelected = true;
//       this.panelSelected = true;
//       this.showcaseSelected = true;
//       this.copSelected = true;
//     },
//     resetDatesFilter() {
//       this.datesFilterOptions.forEach((date) => (date.Selected = true));
//       this.datesOpen = false;
//       this.updateDatesFilterOptionsText();
//     },
//     resetTracksFilter() {
//       this.tracksFilterOptions.forEach((track) => (track.selected = true));
//       this.tracksFiltered = false;
//       this.tracksOpen = false;
//       this.updateTracksFilterOptionsText();
//     },
//     toggleFilter() {
//       this.filterOpen = !this.filterOpen;
//     },
//     toggleRegionsFilter() {
//       this.regionsOpen = !this.regionsOpen;
//     },
//     toggleDatesFilter() {
//       this.datesOpen = !this.datesOpen;
//     },
//     toggleTracksFilter() {
//       this.tracksOpen = !this.tracksOpen;
//     },
//     applyRegionFilter(region) {
//       switch (region) {
//         case "AMERICAS":
//           this.AMselected = !this.AMselected;
//           break;
//         case "ASIA PACIFIC":
//           this.APselected = !this.APselected;
//           break;
//         case "EUROPE":
//           this.EUselected = !this.EUselected;
//           break;
//         case "AFRICA":
//           this.AFselected = !this.AFselected;
//           break;
//       }
//     },
//     filteredList() {
//       if (this.filtersApplied()) {
//         return myProgramsConfiguration.value.filter((session) => {
//           const term = this.searchFilter.toLowerCase();
//           const filteredText =
//             session.Title.toLowerCase().includes(term) ||
//             session.Description.toLowerCase().includes(term) ||
//             session.Type.toLowerCase().includes(term);
//           const filteredSpeakers = session.Profiles.some(
//             (profile) =>
//               profile.Name.toLowerCase().includes(term) ||
//               ("it fellows".includes(term.toLowerCase()) &&
//                 profile.ITFellow === true)
//           );
//           const filteredRegions = session.Regions.some((region) =>
//             isSessionRegionInFilter(region)
//           );
//           const filteredTypes = isSessionTypeInFilter(session.Type);
//           const filteredDates = this.datesFilterOptions
//             .filter((date) => date.Selected)
//             .some(
//               (selectedDate) =>
//                 selectedDate.Date === session.LocalDateTime.Filter
//             );
//           const filteredTracks = this.tracksFilterOptions
//             .filter((track) => track.selected)
//             .some((selectedTrack) =>
//               session.Tracks.includes(selectedTrack.name)
//             );
//           return (
//             (filteredText || filteredSpeakers) &&
//             filteredRegions &&
//             filteredDates &&
//             filteredTypes &&
//             filteredTracks
//           );
//         });
//       }
//       return this.sessionList;
//     },
//     filtersApplied() {
//       return (
//         this.searchFilter.length > 0 ||
//         this.datesFiltered ||
//         this.typesFiltered ||
//         this.regionsFiltered ||
//         this.tracksFiltered
//       );
//     },
//     //end:filter
//     vuenity() {
//       //Filter Dropdowns
//       var dropdownTrigger = document.querySelectorAll(
//         ".em-js-dropdown-trigger"
//       );
//       var dropdownPanel = document.querySelectorAll(".em-js-dropdown-check");
//       var i;
//       for (i = 0; i < dropdownTrigger.length; i++) {
//         dropdownTrigger[i].addEventListener("click", function (e) {
//           e.preventDefault();

//           if (this.parentElement.classList.contains("em-is-active")) {
//             this.classList.remove("em-is-active");
//             this.parentElement.classList.remove("em-is-active");
//           } else {
//             for (j = 0; j < dropdownPanel.length; j++) {
//               dropdownPanel[j].classList.remove("em-is-active");
//               dropdownTrigger[j].classList.remove("em-is-active");
//               this.classList.remove("em-is-active");
//             }
//             this.classList.add("em-is-active");
//             this.parentElement.classList.add("em-is-active");
//           }
//         });
//       }
//       //Search Functionality
//       var searchTrigger = document.querySelectorAll(
//         ".em-js-header-search-trigger"
//       );
//       var j;
//       for (j = 0; j < searchTrigger.length; j++) {
//         searchTrigger[j].addEventListener("click", function (e) {
//           e.preventDefault();
//           var buttonSwap = this.querySelector(".em-js-btn-swap-icon");
//           var iconPath = this.querySelector(".em-js-btn-icon");
//           var navPanel = this.parentNode.parentNode.parentNode.parentNode;
//           var searchPanel = navPanel.querySelector(".em-js-header-search");
//           if (this.classList.contains("em-is-active")) {
//             this.classList.remove("em-is-active");
//             iconPath.setAttribute(
//               "class",
//               "em-c-btn__icon em-c-btn__icon-only em-c-icon--small em-js-btn-icon"
//             );
//             buttonSwap.setAttribute(
//               "class",
//               "em-c-btn__icon em-c-btn__icon-only em-c-icon--small em-js-btn-swap-icon em-u-is-hidden"
//             );
//             searchPanel.classList.remove("em-is-active");
//           } else {
//             this.classList.add("em-is-active");
//             iconPath.setAttribute(
//               "class",
//               "em-c-btn__icon em-c-btn__icon-only em-c-icon--small em-js-btn-icon em-u-is-hidden"
//             );
//             buttonSwap.setAttribute(
//               "class",
//               "em-c-btn__icon em-c-btn__icon-only em-c-icon--small em-js-btn-swap-icon"
//             );
//             searchPanel.classList.add("em-is-active");
//             setTimeout(function () {
//               searchPanel.querySelector("input[type=search]").focus();
//             }, 50);
//           }

//           var navDropdown = document.querySelectorAll(".em-js-nav-dropdown");
//           var navDropdownTrigger = document.querySelectorAll(
//             ".em-js-nav-dropdown-trigger"
//           );
//           var k;
//           for (k = 0; k < navDropdown.length; k++) {
//             navDropdown[k].classList.remove("em-is-active");
//           }
//           for (k = 0; k < navDropdownTrigger.length; k++) {
//             navDropdownTrigger[k].classList.remove("em-is-active");
//           }
//         });
//       }
//     },
//   },

//   computed: {
//     regionsFiltered() {
//       return !(
//         this.AMselected &&
//         this.APselected &&
//         this.EUselected &&
//         this.AFselected
//       );
//     },
//     typesFiltered() {
//       return !(
//         this.keynoteSelected &&
//         this.panelSelected &&
//         this.showcaseSelected &&
//         this.copSelected
//       );
//     },
//     sessionsLoaded() {
//       return this.sessionList.length > 0;
//     },
//     profilesLoaded() {
//       return this.profileList.length > 0;
//     },
//     regionsText() {
//       const numberOfSelectedRegions = [
//         this.AMselected,
//         this.APselected,
//         this.EUselected,
//         this.AFselected,
//       ].filter((region) => region).length;
//       const regionText =
//         numberOfSelectedRegions === 4 ? "All" : numberOfSelectedRegions;
//       return "Regions (" + regionText + ")";
//     },
//     filterError() {
//       return this.filteredList().length === 0;
//     },
//   },
// };
</script>
