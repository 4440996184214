<template>
  <main class="main bg-white">
    <div class="em-l-container">
      <div class="em-c-container__body">
        <section class="em-c-section section">
          <div class="em-c-page-header profile-container">
            <div v-if="profileConfiguration">
              <div class="em-c-container__breadcrumbs breadcrumb-container">
                <ol class="em-c-breadcrumbs" role="navigation">
                  <li class="em-c-breadcrumbs__item breadcrumb-style">
                    <router-link
                      :to="{ name: 'speakers' }"
                      class="em-c-breadcrumbs__link"
                      >Team</router-link
                    >
                  </li>
                  <li class="em-c-breadcrumbs__item breadcrumb-style">
                    <span
                      class="em-c-breadcrumbs__link em-c-breadcrumbs__span"
                      >{{ profileConfiguration?.Name }}</span
                    >
                  </li>
                </ol>
              </div>
              <div class="em-l-grid em-l-grid--2up">
                <div class="em-l-grid__item speaker-photo">
                  <div class="image-wrapper">
                    <div v-if="profileConfiguration?.ITFellow === true" class="flag">
                      IT Fellow
                    </div>
                    <img
                      v-bind:style="{
                        backgroundImage: 'url(' + profileConfiguration?.PhotoLg + ')',
                      }"
                      style="
                        min-height: 225px;
                        min-width: 225px;
                        height: 225px;
                        width: 225px;
                        background-size: cover;
                        background-position: center;
                      "
                      class="em-c-media-block__img em-c-avatar"
                    />
                  </div>
                </div>
                <div
                  class="em-l-grid__item em-l-linelength-container dynamic-bio"
                >
                  <div>
                    <h2 class="profile-name">
                      {{ profileConfiguration?.Name }}
                    </h2>
                    <h3 class="speaker-title">{{ profileConfiguration?.Title }}</h3>
                    <h4 class="em-u-font-style-regular">
                      <span v-if="profileConfiguration?.Team"
                        >{{ profileConfiguration?.Team }}, </span
                      >{{ profileConfiguration?.Location }}
                    </h4>
                    <p></p>
                    <div class="em-l-linelength-container">
                      <p class="speakerBio" v-html="profileConfiguration?.BioSm"></p>
                    </div>
                    <p></p>
                    <h4 class="em-u-font-style-regular"></h4>
                    <p></p>
                    <div class="contact-container">
                      <a class="em-c-btn" v-bind:href="'mailto:' + profileConfiguration?.Email">
                        <div class="em-c-btn__inner">
                          <svg
                            class="em-c-icon em-c-icon--small em-c-btn__icon"
                            data-em-icon-path="images/em-icons.svg"
                          >
                            <use
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              xlink:href="images/em-icons.svg#envelope-closed"
                            ></use>
                          </svg>
                          <span class="em-c-btn__text">E-mail</span>
                        </div>
                      </a>
                    </div>
                    <div v-if="programsConfiguration.length > 0">
                      <h3 class="speaker-title">Sessions</h3>
                      <div style="clear: both">
                        <div
                          v-for="(session, index) in programsConfiguration"
                          v-bind:key="session.Id"
                          v-bind:session="session"
                          v-bind:index="index"
                        >
                          <section class="em-c-section profile-session">
                            <h5
                              class="event-date"
                              v-if="session.LocalDateTime.Same"
                            >
                              <span class="event-day">{{
                                session.LocalDateTime.StartDayText
                              }}</span
                              >, {{ session.LocalDateTime.StartTime }} -
                              {{ session.LocalDateTime.EndTime }}
                              <span class="event-timezone">{{
                                session.LocalDateTime.TimeZone
                              }}</span>
                            </h5>
                            <h5 class="event-date" v-else>
                              <span class="event-day">{{
                                session.LocalDateTime.StartDayText
                              }}</span
                              >, {{ session.LocalDateTime.StartTime }} -
                              <span class="event-day">{{
                                session.LocalDateTime.EndDayText
                              }}</span
                              >, {{ session.LocalDateTime.EndTime }}
                              <span class="event-timezone">{{
                                session.LocalDateTime.TimeZone
                              }}</span>
                            </h5>

                            <div class="em-c-section__body speaker-event">
                              <h5 v-html="session.Title"></h5>
                              <p></p>
                              <p v-html="session.Description"></p>
                              <div class="inline">
                                <div
                                  v-if="
                                    !session.IsSessionInThePast &&
                                    session.Track != 'Workshops' &&
                                    session.CalendarLink
                                  "
                                  class="event-btn block"
                                >
                                  <a
                                    class="em-c-btn"
                                    :href="calendarClick(session?.CalendarLink)"
                                  >
                                    <div class="em-c-btn__inner">
                                      <svg
                                        class="em-c-icon em-c-icon--small em-c-btn__icon"
                                        data-em-icon-path="assets/images/em-icons.svg"
                                      >
                                        <use
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          xlink:href="images/em-icons.svg#calendar"
                                        ></use>
                                      </svg>
                                      <span class="em-c-btn__text"
                                        >Add to calendar</span
                                      >
                                    </div>
                                  </a>
                                </div>
                                <div
                                  v-if="
                                    !session.IsSessionInThePast && session.EventXLink
                                  "
                                  class="event-btn block"
                                >
                                  <a
                                    class="em-c-btn"
                                    v-on:click="workshopClick(session.EventXLink)"
                                    v-on:click.middle="
                                      workshopClick(session.EventXLink)
                                    "
                                  >
                                    <div class="em-c-btn__inner">
                                      <svg
                                        class="em-c-icon em-c-icon--small em-c-btn__icon"
                                        data-em-icon-path="assets/images/em-icons.svg"
                                      >
                                        <use
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          xlink:href="images/em-icons.svg#pencil"
                                        ></use>
                                      </svg>
                                      <span class="em-c-btn__text"
                                        >Register in EventXLink</span
                                      >
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="event-btn block"
                                  v-if="
                                    session.IsSessionInThePast &&
                                    session.ShowRecordedSession
                                  "
                                >
                                  <a
                                    class="em-c-btn em-c-btn--primary"
                                    target="_blank"
                                    :href="session.RecordedSessionLink"
                                  >
                                    <div class="em-c-btn__inner">
                                      <svg
                                        class="em-c-icon em-c-icon--small em-c-btn__icon"
                                        data-em-icon-path="assets/images/em-icons.svg"
                                      >
                                        <use
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          xlink:href="images/em-icons.svg#video-camera"
                                        ></use>
                                      </svg>
                                      <span class="em-c-btn__text"
                                        >Watch recording</span
                                      >
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="event-btn block"
                                  v-if="
                                    session.IsSessionInThePast &&
                                    !!session.PresentationMaterialLink
                                  "
                                >
                                  <a
                                    class="em-c-btn"
                                    target="_blank"
                                    :href="session.PresentationMaterialLink"
                                  >
                                    <div class="em-c-btn__inner">
                                      <svg
                                        class="em-c-icon em-c-icon--small em-c-btn__icon"
                                        data-em-icon-path="assets/images/em-icons.svg"
                                      >
                                        <use
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          xlink:href="images/em-icons.svg#file-pptx"
                                        ></use>
                                      </svg>
                                      <span class="em-c-btn__text"
                                        >View slides</span
                                      >
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="event-btn block"
                                  v-if="
                                    !session.IsSessionInThePast &&
                                    session.ShowSurvey
                                  "
                                >
                                  <a
                                    class="em-c-btn"
                                    target="_blank"
                                    :href="session.SurveyLink"
                                  >
                                    <div class="em-c-btn__inner">
                                      <svg
                                        class="em-c-icon em-c-icon--small em-c-btn__icon"
                                        data-em-icon-path="assets/images/em-icons.svg"
                                      >
                                        <use
                                          xmlns:xlink="http://www.w3.org/1999/xlink"
                                          xlink:href="images/em-icons.svg#envelope-open"
                                        ></use>
                                      </svg>
                                      <span class="em-c-btn__text"
                                        >Submit Questions</span
                                      >
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="em-c-container__breadcrumbs breadcrumb-container">
                <ol class="em-c-breadcrumbs" role="navigation">
                  <li class="em-c-breadcrumbs__item breadcrumb-style">
                    <router-link
                      :to="{ name: 'speakers' }"
                      class="em-c-breadcrumbs__link"
                      >Team</router-link
                    >
                  </li>
                  <li class="em-c-breadcrumbs__item breadcrumb-style">
                    <span class="em-c-breadcrumbs__link em-c-breadcrumbs__span"
                      >Error</span
                    >
                  </li>
                </ol>
              </div>
              <div class="em-u-padding-top-double filter-error">
                <div>
                  <p class="em-c-page-header__desc">
                    <span class="em-u-font-style-semibold filter-red"
                      >No Design Week team members match that name.</span
                    >
                  </p>

                  <p class="em-c-page-header__desc">
                    Please return to the
                    <router-link
                      :to="{ name: 'speakers' }"
                      class="em-c-text-link"
                      >team page</router-link
                    >
                    to view all speakers, instructors, and volunteers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <a id="scrollTop"><i class="icon-chevron-up"></i></a>
  </main>
</template>

<script setup>
import SharePointService from "../services/SharePointService";
import { defineProps, onMounted, ref } from 'vue';
import { setupProgram, calendarClick } from "../utils/SessionUtils";

const props = defineProps(["speakerId"]);

const profileConfiguration = ref({});
const programsConfiguration = ref([]);

onMounted(async () => {
  if (props.speakerId) {
    let profile_config = await SharePointService.getWithFilter("ProfileConfiguration", `?$filter=Id eq ${props.speakerId}`);
    if (profile_config && profile_config.length > 0) {
      profile_config[0].BioSm = profile_config[0].BioSm.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&');
      profileConfiguration.value = profile_config[0];

      let config_programs = await SharePointService.get("UniteProgramList");
      config_programs = config_programs.filter((item) => 
        item.Active && 
        item.Speakers.split(",").findIndex((speaker) => 
          speaker.trim() === profile_config[0].Name) > -1
      ); // Grab only active programs that have the speaker in the list of speakers

      if (config_programs && config_programs.length > 0) {
        for (let i = 0; i < config_programs.length; i++) {
          await setupProgram(config_programs[i]);
        }
      }

      programsConfiguration.value = config_programs;
    }
    else {
      profileConfiguration.value = null;
    }
  }
});
</script>
