<template>
  <main class="main bg-white">
    <div class="em-l-container">
      <div class="em-c-container__body">
        <section class="em-c-section section">
          <div class="em-c-page-header">
            <h1 class="em-c-page-header__title title">FAQ</h1>
            <div class="em-l-linelength-container" v-for="(faq, index) in myConfigurationFAQ" :key="index">
              <h3 class="em-c-page-header__title title">{{ faq.Question }}</h3>
              <div class="em-l-linelength-container">
                <p v-html="faq.Answer"></p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <a id="scrollTop"><i class="icon-chevron-up"></i></a>
  </main>
</template>

<script setup>
import SharePointService from "../services/SharePointService";
import { onMounted, ref } from "vue";

const myConfigurationFAQ = ref([]);

onMounted(async () => {
  let config_faq = await SharePointService.get("FAQConfiguration");
  config_faq = config_faq.filter((item) => item.Active);
  
  for (let i = 0; i < config_faq.length; i++) {
    let faq = config_faq[i];
    faq.Answer = faq.Answer?.replace(/&lt;/g,'<')
      .replace(/&gt;/g,'>')
      .replace(/&amp;/g,'&')
      .replace(/&quot;/g, '"')
      .replace(/&#58;/g, ':');
  }
  
  myConfigurationFAQ.value = config_faq;
});
</script>
