import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import svg4everybody from 'svg4everybody'
import jQuery from '../node_modules/jquery'
import jQueryUI from '../node_modules/jqueryui'
import functions from '../public/js/functions'
import vueSmoothScroll from 'vue-smooth-scroll'
import Loading from 'vue-loading-overlay'
import * as onLoad from 'vue-onload'
import vClickOutside from 'v-click-outside'
import {
  unity
} from '../public/js/vue-unity-1.5.0-modified.js'

//Setup scroll-to and smooth scroll functionality
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);
Vue.use(vueSmoothScroll);

Vue.component('loading', Loading);
Vue.use(onLoad.plugin);
Vue.use(vClickOutside)

Vue.config.productionTip = false;
svg4everybody();

// Setup storage configuration
Vue.use(Vuex);

//authentication.initialize().then(function () {
    new Vue({
      router,
      jQuery,
      jQueryUI,
      Loading,
      functions,
      render: h => h(App),
      mounted() {
        unity();
      }
    }).$mount('#app')
// });
