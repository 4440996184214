import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import FAQ from "@/views/FAQ";
import Sessions from "@/views/Sessions";
import Session from "@/views/Session";
import Speakers from "@/views/Speakers";
import Speaker from "@/views/Speaker";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    props: false,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
    props: false,
  },
  {
    path: "/sessions",
    name: "sessions",
    component: Sessions,
    props: false,
  },
  {
    path: "/sessions/:sessionId",
    name: "session",
    component: Session,
    props: true,
  },
  {
    path: "/speakers",
    name: "speakers",
    component: Speakers,
    props: false,
  },
  {
    path: "/speakers/:speakerId",
    name: "speaker",
    component: Speaker,
    props: true,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return document.querySelector(to.hash).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
