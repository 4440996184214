

export function createTracksFilter(sessionList) {
    let tracksFilterOptions = new Array();
    
    if (sessionList && sessionList.length > 0) {
        sessionList.forEach((session) => {
            session.Tracks.forEach((track) => {
                if (!tracksFilterOptions.find((trackFilter) => trackFilter.name === track)) {
                    tracksFilterOptions.push({
                        name: track,
                        selected: true,
                    });
                }
            });
        });
    
        tracksFilterOptions = tracksFilterOptions.sort((a, b) => (a.name > b.name ? 1 : -1)); 
    }

    return tracksFilterOptions;
}

export function createYearsFilter(sessionList) {
    let yearsFilterOptions = new Array();
    
    if (sessionList && sessionList.length > 0) {
        sessionList.forEach((session) => {
            const filter = session.Year;
        
            if (!yearsFilterOptions.find((year) => year.Year === filter)) {
                yearsFilterOptions.push({
                    Year: filter,
                    Selected: true,
                });
            }
        });
    }

    return yearsFilterOptions;
}

export function createDatesFilter(sessionList) {
    let datesFilterOptions = new Array();
    sessionList.forEach((session) => {
        const filter = session.LocalDateTime.Filter;
        if (!datesFilterOptions.find((date) => date.Date === filter)) {
                datesFilterOptions.push({
                Date: filter,
                Selected: true,
            });
        }
    });
    
    return datesFilterOptions;
}

export function applyRegionFilter(region) {
    switch (region) {
        case "AMERICAS":
            this.AMselected = !this.AMselected;
            break;
        case "ASIA PACIFIC":
            this.APselected = !this.APselected;
            break;
        case "EUROPE":
            this.EUselected = !this.EUselected;
            break;
        case "AFRICA":
            this.AFselected = !this.AFselected;
            break;
        }
}

export function triggerUpdateDatesFilterOptions() {
    this.datesFiltered = this.datesFilterOptions.some((dateFilter) => 
        !dateFilter.Selected
    );
    this.updateDatesFilterOptionsText();
}

export function updateDatesFilterOptionsText(options, filters) {
    const numberOfSelectedDates = options.filter(
        (date) => date.Selected
    ).length;

    const dateText =
        numberOfSelectedDates === options.length
        ? "All"
        : numberOfSelectedDates;

    filters.value.datesText = "Dates (" + dateText + ")";
}

export function updateTracksFilterOptionsText(options, filters) {
    const numberOfSelectedTracks = options.filter(
        (track) => track.selected
    ).length;

    const tracksText =
        numberOfSelectedTracks === options.length
        ? "All"
        : numberOfSelectedTracks;

    filters.value.tracksText = "Tracks (" + tracksText + ")";
}

export function updateRegionsFilterOptionsText(filters) {
    let numberOfSelectedRegions = 0;
    numberOfSelectedRegions += filters.value.AMselected ? 1 : 0;
    numberOfSelectedRegions += filters.value.APselected ? 1 : 0;
    numberOfSelectedRegions += filters.value.EUselected ? 1 : 0;
    numberOfSelectedRegions += filters.value.AFselected ? 1 : 0;

    const regionsText =
        numberOfSelectedRegions === 4
        ? "All"
        : numberOfSelectedRegions;

    filters.value.regionsText = "Regions (" + regionsText + ")";
}