import moment from "moment-timezone";

export function convertDate(date, format = "long") {
  let newDate;

  // Exit if no date is provided
  if (!date) {
    return;
  }

  if (date.startsWith("/Date")) {
    // accounts for Unix/Epoch time
    newDate = new Date(
      Number.parseInt(date.replace("/Date(", "").replace(")/", ""), 10)
    );
  } else {
    newDate = new Date(date);
  }

  // check if newdate is valid
  if (newDate.toString() === "Invalid Date") {
    console.error(`"Invalid Date: ${date}"`);
    return date;
  } else {
    // use Intl.DateTimeFormat to get the formatted complete date
    switch (format) {
      case "long":
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(newDate);
      case "short":
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "UTC",
        }).format(newDate);
      case "month":
        return new Intl.DateTimeFormat("en-US", {
          month: "2-digit",
          year: "numeric",
          timeZone: "UTC",
        }).format(newDate);
      default:
        return null;
    }
  }
}

export function updateEventDateTime(start, end) {
  //Convert startdate and enddate to local timezone
  let browserTimeZone = moment.tz.guess();
  let startMoment = moment.tz(start, browserTimeZone);
  let endMoment = moment.tz(end, browserTimeZone);

  return {
    StartDayText: startMoment.format("dddd, MMM. Do"),
    StartTime: startMoment.format("h:mm a"),
    EndDayText: endMoment.format("dddd, MMM. Do"),
    EndTime: endMoment.format("h:mm a"),
    TimeZone: "(GMT " + moment.tz(browserTimeZone).format("Z") + ")",
    Same: startMoment.format("D") === endMoment.format("D"),
    Filter: startMoment.format("MMM. D"),
  };
}