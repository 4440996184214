import SharePointService from "../services/SharePointService";
import { updateEventDateTime } from "../utils/DateUtils";
import { convertDate } from "../utils/DateUtils";

export function getSessionLink(ID) {
  let windowHref = window.location.origin;
  return windowHref + "/#/sessions/" + ID;
}

export async function shareSession(id) {
  const copiedValue = getSessionLink(id);
  await navigator.clipboard.writeText(copiedValue);

  alert("Session link copied to clipboard");
}

export function getProfile(name, profile_list = []) {
  if (name === null || profile_list.length === 0) {
    return null;
  }

  let profile = profile_list.find((profile, index) => {
    if (profile.Name === name) {
      profile_list[index].Linked = true;
      return true;
    }
    return false;
  });

  if (profile) {
    return profile;
  }

  return {
    ID: 0,
    Name: name,
    Linked: false,
  };
}

export async function setupProgram(session, profile_list = []) {

  if (profile_list.length === 0) {
    let config_profiles = await SharePointService.get("ProfileConfiguration");
    profile_list = config_profiles.filter((item) => item.Active); // Grab only active profiles 
  }

  const start = session.Start;
  const end = session.End;

  session.Start = convertDate(start);
  session.End = convertDate(end);
  session.LocalDateTime = updateEventDateTime(new Date(start), new Date(end));
  session.IsSessionInThePast = new Date() > new Date(session.End),
  session.Speakers = session?.Speakers
    ? session.Speakers.split("; ").map((speaker) => speaker.trim())
    : [];
  session.Regions = session.Regions
    ? session.Regions.split(",").map((region) => region.trim())
    : [];
  session.Profiles = session.Speakers
    ? session.Speakers.map((speaker) =>
      getProfile(speaker.trim(), profile_list))
    : [];
  session.Tracks = session.Tracks
    ? session.Tracks.split(",").map((track) => track.trim())
    : [];
  session.Description = session.Description?.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&');
  session.RecordedSessionLink = session.RecordedSessionLink?.replace(/&#58;/g,':');
  session.PresentationMaterialLink = session.PresentationMaterialLink?.replace(/&#58;/g,':');
  session.EventXLink = session.EventXLink?.substring(59, session.EventXLink?.length);
  session.EventXLink = session.EventXLink?.substring(0, session.EventXLink?.length - 6).replace(/&#58;/g,':');

  return session;
}

export function calendarClick(link) {
  window.open(link);
}
