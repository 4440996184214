<template>
  <div>
    <div v-if="session?.LocalDateTime?.Same" class="event-date inline middle">
      <div class="inline">
        <span class="event-day">
          {{ session?.LocalDateTime?.StartDayText }}</span
        >, {{ session?.LocalDateTime?.StartTime }} -
        {{ session?.LocalDateTime?.EndTime }}
        <span class="event-timezone">{{
          session?.LocalDateTime?.TimeZone
        }}</span>
      </div>
    </div>
    <div v-else class="event-date inline middle">
      <div class="inline">
        <span class="event-day">{{ session?.LocalDateTime?.StartDayText }}</span
        >, {{ session?.LocalDateTime?.StartTime }} -
        <span class="event-day">{{ session?.LocalDateTime?.EndDayText }}</span
        >, {{ session?.LocalDateTime?.EndTime }}
        <span class="event-timezone">{{
          session?.LocalDateTime?.TimeZone
        }}</span>
      </div>
    </div>
    <div class="inline nowrap">
      <span
        v-for="(region, index) in session?.Regions"
        :key="index"
        class="em-c-tags__item em-js-tags-item"
      >
        <a
          class="em-c-tags__link timezone-tag"
          :class="[
            'tags-style',
            {
              active: session?.IsSessionRegionInFilter,
              inactive: !session?.IsSessionRegionInFilter,
            },
          ]"
          :key="region"
          v-on:click="applyRegionFilter(region)"
          >{{ region }}</a
        >
      </span>
      <span
        v-if="filters.regionsFiltered"
        class="em-c-tags__item em-js-tags-item"
      >
        <a class="em-c-tags__link clear-tag" v-on:click="resetRegionsFilter()"
          >Clear filter</a
        >
      </span>
    </div>    
    <div class="em-l-grid em-l-grid--2up event-grid">
      <div class="em-l-grid__item event-photo block">
        <table>
          <tr class="speaker-tr">
            <td
              v-if="session?.Profiles?.length > 0"
              class="speaker-td"
            >
              <router-link
                :to="{
                  name: 'speaker',
                  params: {
                    speakerId: session?.Profiles[0].Id ?? 0,
                  },
                }"
                v-bind:title="session?.Profiles[0].Name"
              >
                <img
                  v-if="session?.Profiles?.length == 1"
                  v-bind:style="{
                    backgroundImage:
                      'url(' +
                      session?.Profiles[0].PhotoMd +
                      ')',
                  }"
                  style="
                    height: 120px;
                    width: 120px;
                    min-height: 120px;
                    min-width: 120px;
                    background-size: cover;
                    background-position: center;
                  "
                  class="em-c-avatar"
                />
                <img
                  v-if="session?.Profiles?.length > 1"
                  v-bind:style="{
                    backgroundImage:
                      'url(' +
                      session?.Profiles[0].PhotoSm +
                      ')',
                  }"
                  style="
                    height: 80px;
                    width: 80px;
                    min-height: 80px;
                    min-width: 80px;
                    background-size: cover;
                    background-position: center;
                  "
                  class="em-c-avatar"
                />
              </router-link>
            </td>
            <td v-if="session?.Profiles?.length >= 2">
              <router-link
                :to="{
                  name: 'speaker',
                  params: {
                    speakerId: session?.Profiles[1].Id ?? 0,
                  },
                }"
                v-bind:title="session?.Profiles[1].Name"
              >
                <img
                  v-bind:style="{
                    backgroundImage:
                      'url(' +
                      session?.Profiles[1].PhotoSm +
                      ')',
                  }"
                  style="
                    height: 80px;
                    width: 80px;
                    min-height: 80px;
                    min-width: 80px;
                    background-size: cover;
                    background-position: center;
                  "
                  class="em-c-avatar"
                />
              </router-link>
            </td>
          </tr>
          <tr
            v-if="session?.Speakers?.length > 2"
            class="speaker-tr"
          >
            <td
              v-if="session?.Profiles?.length >= 3"
              class="speaker-td speaker-td-last"
            >
              <router-link
                :to="{
                  name: 'speaker',
                  params: {
                    speakerId: session?.Profiles[2].Id ?? 0,
                  },
                }"
                v-bind:title="session?.Profiles[2].Name"
              >
                <img
                  v-bind:style="{
                    backgroundImage:
                      'url(' +
                      session?.Profiles[2].PhotoSm +
                      ')',
                  }"
                  style="
                    height: 80px;
                    width: 80px;
                    min-height: 80px;
                    min-width: 80px;
                    background-size: cover;
                    background-position: center;
                  "
                  class="em-c-avatar"
                />
              </router-link>
            </td>
            <td
              v-if="session?.Profiles?.length >= 4"
              class="speaker-td-last"
            >
              <router-link
                :to="{
                  name: 'speaker',
                  params: {
                    speakerId: session?.Profiles[3].Id ?? 0,
                  },
                }"
                v-bind:title="session?.Profiles[3].Name"
              >
                <img
                  v-bind:style="{
                    backgroundImage:
                      'url(' +
                      session?.Profiles[3].PhotoSm +
                      ')',
                  }"
                  style="
                    height: 80px;
                    width: 80px;
                    min-height: 80px;
                    min-width: 80px;
                    background-size: cover;
                    background-position: center;
                  "
                  class="em-c-avatar"
                />
              </router-link>
            </td>
          </tr>

          <tr
            v-if="session?.Profiles?.length > 4"
            class="speaker-tr"
          >
            <td
              v-if="session?.Profiles?.length >= 5"
              class="speaker-td speaker-td-last"
            >
              <router-link
                :to="{
                  name: 'speaker',
                  params: {
                    speakerId: session?.Profiles[4].Id ?? 0,
                  },
                }"
                v-bind:title="session?.Profiles[4].Name"
              >
                <img
                  v-bind:style="{
                    backgroundImage:
                      'url(' +
                      session?.Profiles[4].PhotoSm +
                      ')',
                  }"
                  style="
                    height: 80px;
                    width: 80px;
                    min-height: 80px;
                    min-width: 80px;
                    background-size: cover;
                    background-position: center;
                  "
                  class="em-c-avatar"
                />
              </router-link>
            </td>
            <td
              v-if="session?.Profiles?.length >= 6"
              class="speaker-td-last"
            >
              <router-link
                :to="{
                  name: 'speaker',
                  params: {
                    speakerId: session?.Profiles[5].Id ?? 0,
                  },
                }"
                v-bind:title="session?.Profiles[5].Name"
              >
                <img
                  v-bind:style="{
                    backgroundImage:
                      'url(' +
                      session?.Profiles[5].PhotoSm +
                      ')',
                  }"
                  style="
                    height: 80px;
                    width: 80px;
                    min-height: 80px;
                    min-width: 80px;
                    background-size: cover;
                    background-position: center;
                  "
                  class="em-c-avatar"
                />
              </router-link>
            </td>
          </tr>
        </table>
      </div>
      <div class="em-l-grid__item em-l-linelength-container dynamic">
        <h4 class="eventName">
          <router-link
            :to="{ name: 'session', params: { sessionId: session?.Id ?? 0 } }"
            class="em-c-text-link"
            >{{ session?.Title }}</router-link
          >
        </h4>

        <h5 class="event-speakers">
          <span class="event-type">{{ session?.Type }}</span>
          with
          <span v-for="(speaker, index) in session?.Profiles" :key="index"
            ><span
              v-if="
                Object.keys(session?.Profiles)?.length > 1 &&
                index == Object.keys(session?.Profiles)?.length - 1
              "
            >
              and</span
            >
            <router-link
              :to="{ name: 'speaker', params: { speakerId: speaker.Id ?? 0 } }"
              class="em-c-text-link event-speaker"
            >
              <span class="event-speaker-name"> {{ speaker.Name }}</span>
            </router-link>
            <span
              v-if="
                index != Object.keys(session?.Profiles)?.length - 1 &&
                index != Object.keys(session?.Profiles)?.length - 2
              "
              >,</span
            >
          </span>
        </h5>

        <h5 class="event-track">
          <div class="inline nowrap">
            <span
              v-for="(track, index) in session?.Tracks"
              :key="index"
              class="em-c-tags__item em-js-tags-item"
            >
              <a
                class="em-c-tags__link timezone-tag"
                :class="[
                  'tags-style',
                  {
                    active: session?.IsSessionTrackInFilter,
                    inactive: !session?.IsSessionTrackInFilter,
                  },
                ]"
                :key="track"
                v-on:click="applyTrackFilter(track)"
                >{{ track }}</a
              >
            </span>
          </div>
          Tracks
          <span
            v-if="filters.tracksFiltered"
            class="em-c-tags__item em-js-tags-item"
          >
            <a
              class="em-c-tags__link clear-tag"
              v-on:click="resetTracksFilter()"
              >Clear filter</a
            >
          </span>
        </h5>
        <p v-html="session?.Description" class="event-description"></p>

        <div
          class="em-c-alert"
          v-bind:class="{ 'em-u-is-hidden': !session?.isLinkDisplayed }"
          role="alert"
          :id="session?.Id"
        >
          <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
            <use
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="images/em-iconsession.svg#circle-check"
            ></use>
          </svg>
          <div
            class="em-c-alert__body"
            style="
              overflow-wrap: break-word;
              word-wrap: break-word;
              word-break: break-word;
            "
          >
            Link copied to clipboard:
            <span class="alert-link">
              <router-link
                :to="{ name: 'session', params: { sessionId: session?.Id ?? 0 } }"
              >
                {{ session?.SessionLink }}
              </router-link>
            </span>
          </div>
          <div class="em-c-alert__actions">
            <a v-on:click="closeSessionLink(s)">
              <svg
                class="em-c-icon em-c-icon--small em-c-alert__icon alert-close"
              >
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="images/em-iconsession.svg#x-filled"
                >
                  <title>close</title>
                </use>
              </svg></a
            >
          </div>
        </div>

        <div class="em-c-btn-group">
          <div
            v-if="!session?.IsSessionInThePast && session?.CalendarLink"
            class="event-btn block"
          >
            <a
              class="em-c-btn em-c-btn--primary"
              v-on:click="calendarClick(session?.CalendarLink)"
              v-on:click.middle="calendarClick(session?.CalendarLink)"
            >
              <div class="em-c-btn__inner">
                <svg
                  class="em-c-icon em-c-icon--small em-c-btn__icon"
                  data-em-icon-path="assets/images/em-iconsession.svg"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="images/em-iconsession.svg#calendar"
                  ></use>
                </svg>
                <span class="em-c-btn__text">Add to calendar</span>
              </div>
            </a>
          </div>
          <div
            v-if="
              !session?.IsSessionInThePast &&
              session?.EventXLink
            "
            class="event-btn block"
          >
            <a
              class="em-c-btn em-c-btn--primary"
              v-on:click="calendarClick(session?.EventXLink)"
              v-on:click.middle="
                calendarClick(session?.EventXLink)
              "
            >
              <div class="em-c-btn__inner">
                <svg
                  class="em-c-icon em-c-icon--small em-c-btn__icon"
                  data-em-icon-path="assets/images/em-icons.svg"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="images/em-icons.svg#pencil"
                  ></use>
                </svg>
                <span class="em-c-btn__text"
                  >Register (EventXLink)</span
                >
              </div>
            </a>
          </div>
          <div
            class="event-btn block"
            v-if="session?.IsSessionInThePast && session?.RecordedSessionLink"
          >
            <a
              class="em-c-btn em-c-btn--primary"
              target="_blank"
              :href="session?.RecordedSessionLink"
            >
              <div class="em-c-btn__inner">
                <svg
                  class="em-c-icon em-c-icon--small em-c-btn__icon"
                  data-em-icon-path="assets/images/em-icons.svg"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="images/em-icons.svg#video-camera"
                  ></use>
                </svg>
                <span class="em-c-btn__text">Watch recording</span>
              </div>
            </a>
          </div>
          <div
            class="event-btn block"
            v-if="
              session?.IsSessionInThePast &&
              !!session?.PresentationMaterialLink
            "
          >
            <a
              class="em-c-btn"
              target="_blank"
              :href="session?.PresentationMaterialLink"
            >
              <div class="em-c-btn__inner">
                <svg
                  class="em-c-icon em-c-icon--small em-c-btn__icon"
                  data-em-icon-path="assets/images/em-icons.svg"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="images/em-icons.svg#file-pptx"
                  ></use>
                </svg>
                <span class="em-c-btn__text">View slides</span>
              </div>
            </a>
          </div>
          <div class="event-btn block">
            <a
              class="em-c-btn"
              v-on:click="shareSession(session?.Id)"
              v-on:click.middle="shareSession(session?.Id)"
            >
              <div class="em-c-btn__inner">
                <svg
                  class="em-c-icon em-c-icon--small em-c-btn__icon"
                  data-em-icon-path="assets/images/em-icons.svg"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="images/em-icons.svg#share"
                  ></use>
                </svg>
                <span class="em-c-btn__text">Share session</span>
              </div>
            </a>
          </div>
          <div
            class="event-btn block"
            v-if="!session?.IsSessionInThePast && session?.ShowSurvey"
          >
            <a class="em-c-btn" target="_blank" :href="session?.SurveyLink">
              <div class="em-c-btn__inner">
                <svg
                  class="em-c-icon em-c-icon--small em-c-btn__icon"
                  data-em-icon-path="assets/images/em-iconsession.svg"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="images/em-iconsession.svg#envelope-open"
                  ></use>
                </svg>
                <span class="em-c-btn__text">Submit Questions</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { isSessionRegionInFilter, isSessionTrackInFilter, filteredList } from "../utils/SessionsFilters";
import { getSessionLink, shareSession, calendarClick } from "../utils/SessionUtils";
import { defineProps, onMounted, ref } from "vue";
import { updateRegionsFilterOptionsText, updateTracksFilterOptionsText } from "../utils/FilterUtils";

const props = defineProps(["session", "sessionList", "filters", "tracksFilterOptions", 
"dateFilterOptions", "updateFilters", "updateSessionList",
]);

const session = ref({});

onMounted(() => {
  if (props.session) {
    session.value = {
        ...props.session,
        IsSessionRegionInFilter: isSessionRegionInFilter(props.session.Regions),
        IsSessionTrackInFilter: isSessionTrackInFilter(props.session.Tracks),
        SessionLink: getSessionLink(props.session.Id, props.tracksFilterOptions),
    };
  }
});

function applyTrackFilter(track) {
  if (props.tracksFilterOptions && props.tracksFilterOptions.length > 0) {
    props.tracksFilterOptions.forEach((trackFilter) => {
      trackFilter.selected = trackFilter.name === track;
    });
    
    triggerUpdateTracksFilterOptions();
  }  
}

function applyRegionFilter(region) {
  let newFilters = props.filters;
  switch (region) {
    case "AMERICAS":
      newFilters.AMselected = true;
      newFilters.AFselected = false;
      newFilters.APselected = false;
      newFilters.EUselected = false;
      break;
    case "AFRICA":
      newFilters.AMselected = false;
      newFilters.AFselected = true;
      newFilters.APselected = false;
      newFilters.EUselected = false;
      break;
    case "ASIA PACIFIC":
      newFilters.AMselected = false;
      newFilters.AFselected = false;
      newFilters.APselected = true;
      newFilters.EUselected = false;
      break;
    case "EUROPE":
      newFilters.AMselected = false;
      newFilters.AFselected = false;
      newFilters.APselected = false;
      newFilters.EUselected = true;
      break;
    default:
      break;
  }

  triggerUpdateRegionsFilterOptions(newFilters);
}

function triggerUpdateTracksFilterOptions() {
  let newFilters = props.filters;
  newFilters.tracksFiltered = props.tracksFilterOptions.some((track) => 
      !track.selected
  );
  
  updateTracksFilterOptionsText(props.tracksFilterOptions, newFilters);
  props.updateSessionList(filteredList(newFilters, props.sessionList, props.dateFilterOptions, props.tracksFilterOptions));
  props.updateFilters(newFilters);
}

function triggerUpdateRegionsFilterOptions(filters) {
  filters.regionsFiltered = true;

  updateRegionsFilterOptionsText(filters);
  props.updateSessionList(filteredList(filters, props.sessionList, props.dateFilterOptions, props.tracksFilterOptions));
  props.updateFilters(filters);
}

function resetRegionsFilter() {
  let newFilters = props.filters;

  newFilters.AMselected = true;
  newFilters.AFselected = true;
  newFilters.APselected = true;
  newFilters.EUselected = true;

  props.updateSessionList(filteredList(newFilters, props.sessionList, props.dateFilterOptions, props.tracksFilterOptions));
  props.updateFilters(newFilters);
}

function resetTracksFilter() {
  props.tracksFilterOptions.forEach((trackFilter) => {
      trackFilter.selected = true;
  });

  props.updateSessionList(filteredList(props.filters, props.sessionList, props.dateFilterOptions, props.tracksFilterOptions));
}
</script>
