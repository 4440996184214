<template>
  <header
    class="em-c-header em-c-header--condensed em-u-margin-bottom-none bg-white"
    role="banner"
    style="top: 0 !important; opacity: 0.95"
  >
    <div class="em-l-container em-c-header__inner">
      <div class="em-c-header__body">
        <div class="em-c-header__title-container">
          <h2 class="em-c-header__title">
            <img class="exxon-logo" src="images/unite/exxonmobil_red.png" />
            <img class="unite-logo" src="images/unite/Unite2Asset2@4x.png" />
          </h2>
        </div>
      </div>
    </div>

    <div class="em-c-hero em-c-hero--bare hero">
      <div class="em-l-container">
        <div class="em-c-container__body section">
          <router-link :to="{ name: 'home' }" class="header-link">
            <h3 class="em-u-font-style-regular">September 10 - 12, 2024</h3>
            <h1 class="em-c-hero__title em-u-font-style-bold">UNITE 2024</h1>
          </router-link>
          <div class="links-div em-u-font-style-bold">
            <!-- <router-link :to="{ name: 'home' }" class="links-style"
              >Attend</router-link
            > -->
            <router-link :to="{ name: 'sessions', query: { year: 2024 } }" class="links-style"
              >Program</router-link
            >
            <router-link :to="{ name: 'speakers' }" class="links-style"
              >Team</router-link
            >
            <router-link :to="{ name: 'faq' }" class="links-style"
              >FAQ</router-link
            >
            <router-link :to="{ name: 'sessions', query: { year: 2023 } }" class="links-style"
              >2023 Recordings</router-link
            >
            <router-link :to="{ name: 'sessions', query: { year: 2022 } }" class="links-style"
              >2022 Recordings</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {};
</script>

<style scoped>
.section {
  padding: 3em;
}
.hero {
  background: #bae2f8;
  color: #111122;
  border: none !important;
  padding: 1em 0em;
}
.links-div {
  font-size: 1.2em;
}
@media all and (min-width: 35em) {
  h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 0.4rem;
  }

  .links-div {
    font-size: 1.4em;
  }
}
.links-style {
  line-height: 1.5em;
  padding-bottom: 0.5em;
  margin-right: 2em;
  color: #111122;
}

.links-style:hover {
  border-bottom: solid 3px #111122;
}

.router-link-active,
.router-link-active:hover {
  border-bottom: solid 3px #0c69b0;
  color: #0c69b0 !important;
}
.em-c-hero__title {
  margin-left: -0.075em;
}
.header-link,
.header-link:hover {
  color: #111122 !important;
}
.em-c-header__inner {
  height: 64px;
}
.em-c-header__title {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  align-items: flex-start;
}
.exxon-logo {
  height: 40px;
  width: auto;
  padding-right: 4px;
  border-right: 1px solid lightgrey;
}

.unite-logo {
  height: 52px;
  width: auto;
  padding-left: 4px;
}
</style>
