<template>
  <footer
    class="em-c-footer em-u-margin-top-none bg-deepblue "
    role="contentinfo"
    style="border-top:none;"
  >
    <div class="em-l-container">
      <div class="em-c-footer__inner" style="display: flex; justify-content: space-between;align-items: center;">
        <router-link 
          to="/"
          rel="home"
          class="em-c-header__title-link color-deepblue"
          v-scroll-to="'#top'"
          v-smooth-scroll
          ><h2 class="em-c-header__title"><img src="../../../public/images/unite/exxonmobil_white.png"/></h2>
        </router-link>
        <!-- end em-c-footer-nav -->
      </div>
      <!-- end em-c-footer__inner -->
    </div>
    <!-- end em-l-container -->
  </footer>
  <!-- end em-c-footer -->
</template>

<script>
export default {  }
</script>

<style scoped>
</style>
