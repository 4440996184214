<template>
  <main class="main bg-white">
    <div class="em-l-container">
      <div class="em-c-container__body">
        <section class="em-c-section section">
          <h1 class="em-l-linelength-container em-c-page-header__title title title-line-height">
            {{ myConfigurationHome.Title }}
            <p class="em-u-font-style-semibold">{{ myConfigurationHome.HeroTitle }}</p>
          </h1>
          <h2 class="em-c-page-header__title title">
            <p>Coming in:</p><span id="countdown">{{ countdown(myConfigurationHome.From) }}</span>
          </h2>
          <div class="em-l-linelength-container-long">
            <p class="em-c-page-header__desc">
              {{ myConfigurationHome.Description }}
            </p>
            <div class="em-c-btn-group" v-if="myConfigurationHome.SuggestTopicButton">
              <div class="event-btn block">
                <a :href="myConfigurationHome.SuggestTopicLink">
                  <div class="em-c-btn em-c-btn--primary">
                    <div class="em-c-btn__inner">
                      <svg class="em-c-icon em-c-icon--small em-c-btn__icon"
                        data-em-icon-path="assets/images/em-icons.svg">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="images/em-icons.svg#pencil"></use>
                      </svg>
                      <span class="em-c-btn__text">Suggest a topic</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="em-c-btn-group" v-if="myConfigurationHome.AgendaButton">
              <div class="event-btn block">
                <router-link :to="{ name: 'sessions', query: { year: 2024 } }" class="em-c-btn em-c-btn--primary">
                  Agenda
                </router-link>
              </div>
            </div>
            <h2 class="em-l-linelength-container em-c-page-header__title title title-h2">
              Attend UNITE to:
            </h2>
          </div>
          <div class="em-l-grid em-l-grid--3up">
            <div class="em-l-grid__item padding-btm-lg">
              <div class="details">
                <div class="details-text em-u-text-align-center">
                  <div class="details-icon">
                    <img src="../../public/images/unite/learn.png" />
                  </div>
                  <h3>Learn</h3>
                  <span>
                    Learn new skills, solve problems and get inspired with tech
                    leaders
                  </span>
                </div>
              </div>
            </div>
            <div class="em-l-grid__item padding-btm-lg">
              <div class="details">
                <div class="details-text em-u-text-align-center">
                  <div class="details-icon">
                    <img src="../../public/images/unite/explore.png" />
                  </div>
                  <h3>Explore</h3>
                  <span>
                    Align on goals, explore new possibilities, and celebrate
                    learnings
                  </span>
                </div>
              </div>
            </div>
            <div class="em-l-grid__item padding-btm-lg">
              <div class="details">
                <div class="details-text em-u-text-align-center">
                  <div class="details-icon">
                    <img src="../../public/images/unite/connect.png" />
                  </div>
                  <h3>Connect</h3>
                  <span class="padding-btm-lg">
                    Join tech communities and meet technical talent across sites
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <a id="scrollTop"><i class="icon-chevron-up"></i></a>
  </main>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import SharePointService from '../services/SharePointService';
import { countdown } from '../utils/Countdown';
import { convertDate } from '../utils/DateUtils';

const myConfigurationHome = ref([]);

onMounted(async () => {
  let config_home = await SharePointService.get("HomeConfiguration");
  config_home[0].From = convertDate(config_home[0].From);
  myConfigurationHome.value = config_home[0];
});

onUnmounted(() => {
  clearInterval(countdown);
});
</script>
