var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{staticClass:"main bg-white"},[_c('div',{staticClass:"em-l-container"},[_c('div',{staticClass:"em-c-container__body"},[_c('section',{staticClass:"em-c-section section"},[_c('div',{staticClass:"em-c-page-header profile-container"},[(_setup.programConfiguration)?_c('div',[_c('div',{staticClass:"em-c-container__breadcrumbs breadcrumb-container"},[_c('ol',{staticClass:"em-c-breadcrumbs",attrs:{"role":"navigation"}},[_c('li',{staticClass:"em-c-breadcrumbs__item breadcrumb-style"},[_c('router-link',{staticClass:"em-c-breadcrumbs__link",attrs:{"to":{ name: 'sessions' }}},[_vm._v("Program")])],1),_c('li',{staticClass:"em-c-breadcrumbs__item breadcrumb-style"},[_c('span',{staticClass:"em-c-breadcrumbs__link em-c-breadcrumbs__span"},[_vm._v(_vm._s(_setup.programConfiguration?.Title))])])])]),(_setup.programConfiguration)?_c('div',{staticClass:"event-list"},[_c('div',{staticClass:"em-l-grid em-l-grid--2up event-grid",staticStyle:{"clear":"both"}},[_c('div',{staticClass:"em-l-grid__item event-photo block"},[_c('table',[_c('tr',{staticClass:"speaker-tr"},[(_setup.programConfiguration?.Profiles.length > 0)?_c('td',{staticClass:"speaker-td"},[_c('router-link',{attrs:{"to":{
                            name: 'speaker',
                            params: {
                              speakerId: _setup.programConfiguration?.Profiles[0].Id,
                            },
                          },"title":_setup.programConfiguration?.Profiles[0].Name}},[(_setup.programConfiguration?.Profiles.length == 1)?_c('img',{staticClass:"em-c-avatar",staticStyle:{"height":"120px","width":"120px","min-height":"120px","min-width":"120px","background-size":"cover","background-position":"center"},style:({
                              backgroundImage:
                                'url(' +
                                _setup.programConfiguration?.Profiles[0].PhotoMd +
                                ')',
                            })}):_vm._e(),(_setup.programConfiguration?.Profiles.length > 1)?_c('img',{staticClass:"em-c-avatar",staticStyle:{"height":"80px","width":"80px","min-height":"80px","min-width":"80px","background-size":"cover","background-position":"center"},style:({
                              backgroundImage:
                                'url(' +
                                _setup.programConfiguration?.Profiles[0].PhotoSm +
                                ')',
                            })}):_vm._e()])],1):_vm._e(),(_setup.programConfiguration?.Profiles.length >= 2)?_c('td',[_c('router-link',{attrs:{"to":{
                            name: 'speaker',
                            params: {
                              speakerId: _setup.programConfiguration?.Profiles[1].Id,
                            },
                          },"title":_setup.programConfiguration?.Profiles[1].Name}},[_c('img',{staticClass:"em-c-avatar",staticStyle:{"height":"80px","width":"80px","min-height":"80px","min-width":"80px","background-size":"cover","background-position":"center"},style:({
                              backgroundImage:
                                'url(' +
                                _setup.programConfiguration?.Profiles[1].PhotoSm +
                                ')',
                            })})])],1):_vm._e()]),(_setup.programConfiguration?.Speakers.length > 2)?_c('tr',{staticClass:"speaker-tr"},[(_setup.programConfiguration?.Profiles.length >= 3)?_c('td',{staticClass:"speaker-td speaker-td-last"},[_c('router-link',{attrs:{"to":{
                            name: 'speaker',
                            params: {
                              speakerId: _setup.programConfiguration?.Profiles[2].Id,
                            },
                          },"title":_setup.programConfiguration?.Profiles[2].Name}},[_c('img',{staticClass:"em-c-avatar",staticStyle:{"height":"80px","width":"80px","min-height":"80px","min-width":"80px","background-size":"cover","background-position":"center"},style:({
                              backgroundImage:
                                'url(' +
                                _setup.programConfiguration?.Profiles[2].PhotoSm +
                                ')',
                            })})])],1):_vm._e(),(_setup.programConfiguration?.Profiles.length >= 4)?_c('td',{staticClass:"speaker-td-last"},[_c('router-link',{attrs:{"to":{
                            name: 'speaker',
                            params: {
                              speakerId: _setup.programConfiguration?.Profiles[3].Id,
                            },
                          },"title":_setup.programConfiguration?.Profiles[3].Name}},[_c('img',{staticClass:"em-c-avatar",staticStyle:{"height":"80px","width":"80px","min-height":"80px","min-width":"80px","background-size":"cover","background-position":"center"},style:({
                              backgroundImage:
                                'url(' +
                                _setup.programConfiguration?.Profiles[3].PhotoSm +
                                ')',
                            })})])],1):_vm._e()]):_vm._e(),(_setup.programConfiguration?.Profiles.length > 4)?_c('tr',{staticClass:"speaker-tr"},[(_setup.programConfiguration?.Profiles.length >= 5)?_c('td',{staticClass:"speaker-td speaker-td-last"},[_c('router-link',{attrs:{"to":{
                            name: 'speaker',
                            params: {
                              speakerId: _setup.programConfiguration?.Profiles[4].Id,
                            },
                          },"title":_setup.programConfiguration?.Profiles[4].Name}},[_c('img',{staticClass:"em-c-avatar",staticStyle:{"height":"80px","width":"80px","min-height":"80px","min-width":"80px","background-size":"cover","background-position":"center"},style:({
                              backgroundImage:
                                'url(' +
                                _setup.programConfiguration?.Profiles[4].PhotoSm +
                                ')',
                            })})])],1):_vm._e(),(_setup.programConfiguration?.Profiles.length >= 6)?_c('td',{staticClass:"speaker-td-last"},[_c('router-link',{attrs:{"to":{
                            name: 'speaker',
                            params: {
                              speakerId: _setup.programConfiguration?.Profiles[5].Id,
                            },
                          },"title":_setup.programConfiguration?.Profiles[5].Name}},[_c('img',{staticClass:"em-c-avatar",staticStyle:{"height":"80px","width":"80px","min-height":"80px","min-width":"80px","background-size":"cover","background-position":"center"},style:({
                              backgroundImage:
                                'url(' +
                                _setup.programConfiguration?.Profiles[5].PhotoSm +
                                ')',
                            })})])],1):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"em-l-grid__item em-l-linelength-container dynamic"},[_c('h3',{staticClass:"eventName",domProps:{"innerHTML":_vm._s(_setup.programConfiguration?.Title)}}),(_setup.programConfiguration?.LocalDateTime.Same)?_c('h5',{staticClass:"event-date inline middle"},[_c('div',{staticClass:"inline"},[_c('span',{staticClass:"event-day"},[_vm._v(_vm._s(_setup.programConfiguration?.LocalDateTime.StartDayText))]),_vm._v(", "+_vm._s(_setup.programConfiguration?.LocalDateTime.StartTime)+" - "+_vm._s(_setup.programConfiguration?.LocalDateTime.EndTime)+" "),_c('span',{staticClass:"event-timezone"},[_vm._v(_vm._s(_setup.programConfiguration?.LocalDateTime.TimeZone))])])]):_c('h5',{staticClass:"event-date inline middle"},[_c('div',{staticClass:"inline"},[_c('span',{staticClass:"event-day"},[_vm._v(_vm._s(_setup.programConfiguration?.LocalDateTime.StartDayText))]),_vm._v(", "+_vm._s(_setup.programConfiguration?.LocalDateTime.StartTime)+" - "),_c('span',{staticClass:"event-day"},[_vm._v(_vm._s(_setup.programConfiguration?.LocalDateTime.EndDayText))]),_vm._v(", "+_vm._s(_setup.programConfiguration?.LocalDateTime.EndTime)+" "),_c('span',{staticClass:"event-timezone"},[_vm._v(_vm._s(_setup.programConfiguration?.LocalDateTime.TimeZone))])])]),_c('h5',{staticClass:"event-speakers"},[_c('span',{staticClass:"event-type"},[_vm._v(_vm._s(_setup.programConfiguration?.Type))]),_vm._v(" with "),_vm._l((_setup.programConfiguration?.Profiles),function(sp,index){return _c('span',{key:index},[(
                          Object.keys(_setup.programConfiguration?.Profiles).length > 1 &&
                          index ==
                            Object.keys(_setup.programConfiguration?.Profiles).length - 1
                        )?_c('span',[_vm._v(" and")]):_vm._e(),_c('router-link',{staticClass:"em-c-text-link event-speaker",attrs:{"to":{
                          name: 'speaker',
                          params: { speakerId: sp.Id },
                        }}},[_c('span',{staticClass:"event-speaker-name"},[_vm._v(" "+_vm._s(sp.Name))])]),(
                          index !=
                            Object.keys(_setup.programConfiguration?.Profiles).length -
                              1 &&
                          index !=
                            Object.keys(_setup.programConfiguration?.Profiles).length - 2
                        )?_c('span',[_vm._v(",")]):_vm._e()],1)})],2),_c('h5',{staticClass:"event-track"},[_c('div',{staticClass:"inline nowrap"},_vm._l((_setup.programConfiguration?.Tracks),function(t,index){return _c('span',{key:index,staticClass:"em-c-tags__item em-js-tags-item"},[_c('a',{key:t,staticClass:"em-c-tags__link timezone-tag active"},[_vm._v(_vm._s(t))])])}),0),_vm._v(" Tracks ")]),_c('p',{staticClass:"event-description",domProps:{"innerHTML":_vm._s(_setup.programConfiguration?.Description)}}),_c('div',{staticClass:"inline"},[(
                        !_setup.programConfiguration?.IsSessionInThePast &&
                        _setup.programConfiguration?.CalendarLink
                      )?_c('div',{staticClass:"event-btn block"},[_c('a',{staticClass:"em-c-btn em-c-btn--primary",attrs:{"href":_setup.calendarClick(_setup.programConfiguration?.CalendarLink)}},[_c('div',{staticClass:"em-c-btn__inner"},[_c('svg',{staticClass:"em-c-icon em-c-icon--small em-c-btn__icon",attrs:{"data-em-icon-path":"assets/images/em-icons.svg"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"images/em-icons.svg#calendar"}})]),_c('span',{staticClass:"em-c-btn__text"},[_vm._v("Add to calendar")])])])]):_vm._e(),(
                        !_setup.programConfiguration?.IsSessionInThePast &&
                        _setup.programConfiguration?.EventXLink
                      )?_c('div',{staticClass:"event-btn block"},[_c('a',{staticClass:"em-c-btn em-c-btn--primary",attrs:{"href":_setup.calendarClick(_setup.programConfiguration?.EventXLink)}},[_c('div',{staticClass:"em-c-btn__inner"},[_c('svg',{staticClass:"em-c-icon em-c-icon--small em-c-btn__icon",attrs:{"data-em-icon-path":"assets/images/em-icons.svg"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"images/em-icons.svg#pencil"}})]),_c('span',{staticClass:"em-c-btn__text"},[_vm._v("Register (EventXLink)")])])])]):_vm._e(),(_setup.programConfiguration?.IsSessionInThePast && _setup.programConfiguration?.RecordedSessionLink)?_c('div',{staticClass:"event-btn block"},[_c('a',{staticClass:"em-c-btn em-c-btn--primary",attrs:{"target":"_blank","href":_setup.programConfiguration?.RecordedSessionLink}},[_c('div',{staticClass:"em-c-btn__inner"},[_c('svg',{staticClass:"em-c-icon em-c-icon--small em-c-btn__icon",attrs:{"data-em-icon-path":"assets/images/em-icons.svg"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"images/em-icons.svg#video-camera"}})]),_c('span',{staticClass:"em-c-btn__text"},[_vm._v("Watch recording")])])])]):_vm._e(),(
                        _setup.programConfiguration?.IsSessionInThePast &&
                        !!_setup.programConfiguration?.PresentationMaterialLink
                      )?_c('div',{staticClass:"event-btn block"},[_c('a',{staticClass:"em-c-btn",attrs:{"target":"_blank","href":_setup.programConfiguration?.PresentationMaterialLink}},[_c('div',{staticClass:"em-c-btn__inner"},[_c('svg',{staticClass:"em-c-icon em-c-icon--small em-c-btn__icon",attrs:{"data-em-icon-path":"assets/images/em-icons.svg"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"images/em-icons.svg#file-pptx"}})]),_c('span',{staticClass:"em-c-btn__text"},[_vm._v("View slides")])])])]):_vm._e(),_c('div',{staticClass:"event-btn block"},[_c('a',{staticClass:"em-c-btn",on:{"click":function($event){return _setup.shareSession(_setup.programConfiguration?.Id)},"mouseup":function($event){if('button' in $event && $event.button !== 1)return null;return _setup.shareSession(_setup.programConfiguration?.Id)}}},[_c('div',{staticClass:"em-c-btn__inner"},[_c('svg',{staticClass:"em-c-icon em-c-icon--small em-c-btn__icon",attrs:{"data-em-icon-path":"assets/images/em-icons.svg"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"images/em-icons.svg#share"}})]),_c('span',{staticClass:"em-c-btn__text"},[_vm._v("Share session")])])])]),(
                        !_setup.programConfiguration?.IsSessionInThePast &&
                        _setup.programConfiguration?.ShowSurvey
                      )?_c('div',{staticClass:"event-btn block"},[_c('a',{staticClass:"em-c-btn",attrs:{"target":"_blank","href":_setup.programConfiguration?.SurveyLink}},[_c('div',{staticClass:"em-c-btn__inner"},[_c('svg',{staticClass:"em-c-icon em-c-icon--small em-c-btn__icon",attrs:{"data-em-icon-path":"assets/images/em-icons.svg"}},[_c('use',{attrs:{"xmlns:xlink":"http://www.w3.org/1999/xlink","xlink:href":"images/em-icons.svg#envelope-open"}})]),_c('span',{staticClass:"em-c-btn__text"},[_vm._v("Submit Questions")])])])]):_vm._e()])])])]):_vm._e()]):_c('div',[_c('div',{staticClass:"em-c-container__breadcrumbs breadcrumb-container"},[_c('ol',{staticClass:"em-c-breadcrumbs",attrs:{"role":"navigation"}},[_c('li',{staticClass:"em-c-breadcrumbs__item breadcrumb-style"},[_c('router-link',{staticClass:"em-c-breadcrumbs__link",attrs:{"to":{ name: 'sessions' }}},[_vm._v("Program")])],1),_vm._m(0)])]),_c('div',{staticClass:"em-u-padding-top-double filter-error"},[_c('div',[_vm._m(1),_c('p',{staticClass:"em-c-page-header__desc"},[_vm._v(" Please return to the "),_c('router-link',{staticClass:"em-c-text-link",attrs:{"to":{ name: 'sessions' }}},[_vm._v("program page")]),_vm._v(" to view all sessions. ")],1)])])])])])])]),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"em-c-breadcrumbs__item breadcrumb-style"},[_c('span',{staticClass:"em-c-breadcrumbs__link em-c-breadcrumbs__span"},[_vm._v("Error")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"em-c-page-header__desc"},[_c('span',{staticClass:"em-u-font-style-semibold filter-red"},[_vm._v("No UNITE event matches that ID. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"id":"scrollTop"}},[_c('i',{staticClass:"icon-chevron-up"})])
}]

export { render, staticRenderFns }