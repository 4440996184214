let currentFilters = {};

export const defaultFilters = {
  filterOpen: true,
  regionsOpen: false,
  tracksOpen: false,
  datesOpen: false,
  searchFilter: "",
  AMselected: true,
  APselected: true,
  EUselected: true,
  AFselected: true,
  keynoteSelected: true,
  panelSelected: true,
  showcaseSelected: true,
  copSelected: true,
  datesFiltered: false,
  typesFiltered: false,
  regionsFiltered: false,
  tracksFiltered: false,
  yearsFiltered: false,
  datesText: "",
  tracksText: "",
  yearsText: ""
};

export function filteredList(
    filters, 
    sessionsList, 
    datesFilterOptions = [], 
    tracksFilterOptions = []
) {
    currentFilters = filters;

    return sessionsList.filter((session) => {
        const term = filters.searchFilter?.toLowerCase();
        
        const filteredText =
            session.Title?.toLowerCase().includes(term) ||
            session.Description?.toLowerCase().includes(term) ||
            session.Type?.toLowerCase().includes(term);
        
        const filteredSpeakers = session.Profiles.some(
            (profile) =>
                profile.Name?.toLowerCase().includes(term) ||
                ("it fellows".includes(term?.toLowerCase()) &&
                    profile.ITFellow === true)
        );
        
        const filteredRegions = session.Regions.some((region) =>
            isSessionRegionInFilter(region)
        );
        
        const filteredTypes = isSessionTypeInFilter(session.ProgramType);
        
        const filteredDates = datesFilterOptions
            .filter((date) => date.Selected)
            .some((selectedDate) => selectedDate.Date === session.LocalDateTime.Filter);
        
        const filteredTracks = tracksFilterOptions
            .filter((track) => track.selected)
            .some((selectedTrack) => session.Tracks.includes(selectedTrack.name));
        
        return (
            (filteredText || filteredSpeakers) &&
            filteredRegions &&
            filteredDates &&
            filteredTypes &&
            filteredTracks
        );
    });
}

export function filtersApplied(filters) {
    return filters && (
        filters.searchFilter?.length > 0 ||
        filters.datesFiltered ||
        filters.typesFiltered ||
        filters.regionsFiltered ||
        filters.tracksFiltered
    );
}

export function isSessionRegionInFilter(region) {
    switch (region) {
      case "AMERICAS":
        return currentFilters.AMselected;
      case "ASIA PACIFIC":
        return currentFilters.APselected;
      case "EUROPE":
        return currentFilters.EUselected;
      case "AFRICA":
        return currentFilters.AFselected;
      default:
        return false;
    }
}

export function isSessionTypeInFilter(type) {
    switch (type) {
        case "Keynote":
          return currentFilters.keynoteSelected;
        case "Panel":
          return currentFilters.panelSelected;
        case "Showcase":
          return currentFilters.showcaseSelected;
        case "CoP":
          return currentFilters.copSelected;
        default:
          return false;
    }
}

export function isSessionTrackInFilter(track, tracksFilterOptions = []) {
    return tracksFilterOptions.some(
        (trackFilter) => trackFilter.name === track && trackFilter.selected
    );
}
