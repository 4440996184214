var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{staticClass:"main bg-white"},[_c('div',{staticClass:"em-l-container"},[_c('div',{staticClass:"em-c-container__body"},[_c('section',{staticClass:"em-c-section section"},[_c('div',{staticClass:"em-c-page-header"},[_c('h1',{staticClass:"em-c-page-header__title title"},[_vm._v("Team")]),_c('p',{staticClass:"em-c-page-header__desc"},[_vm._v(" Learn more about our team of speakers, sponsors, planning, volunteers and hosts by clicking on each person to view their biography and session details. "),_c('router-link',{staticClass:"em-c-text-link",attrs:{"to":{ name: 'sessions' }}},[_vm._v(" View all events here ")]),_vm._v(". ")],1),_c('h3',{staticClass:"em-c-page-header__title title"},[_vm._v(" Sponsors: Chief Architects and Site Technical Leads ")]),_c('ul',{staticClass:"em-c-tile-list em-u-margin-bottom-double em-l-grid em-l-grid--1-2-4up"},_vm._l((_setup.sponsors),function(p,index){return _c('li',{key:p.Id,staticClass:"em-c-tile-list__item em-l-grid__item",attrs:{"p":p,"index":index}},[_c('router-link',{staticClass:"em-c-tile",attrs:{"to":{ name: 'speaker', params: { speakerId: p.Id } },"title":p.Name}},[_c('div',{staticClass:"image-wrapper"},[(p.ITFellow === true)?_c('div',{staticClass:"flag"},[_vm._v(" IT Fellow ")]):_vm._e(),_c('img',{staticClass:"em-c-media-block__img em-c-avatar",staticStyle:{"height":"120px","width":"120px","background-size":"cover","background-position":"center"},style:({
                      backgroundImage: 'url(' + p.PhotoMd + ')',
                    })})]),_c('p',{staticClass:"speaker-headline",domProps:{"innerHTML":_vm._s(p.Name)}}),_c('p',{staticClass:"speaker-team",domProps:{"innerHTML":_vm._s(p.Title)}})])],1)}),0),_c('p'),_c('h3',{staticClass:"em-c-page-header__title title"},[_vm._v("Planning Team")]),_c('ul',{staticClass:"em-c-tile-list em-u-margin-bottom-double em-l-grid em-l-grid--1-2-4up"},_vm._l((_setup.planners),function(p,index){return _c('li',{key:p.Id,staticClass:"em-c-tile-list__item em-l-grid__item",attrs:{"p":p,"index":index}},[_c('router-link',{staticClass:"em-c-tile",attrs:{"to":{ name: 'speaker', params: { speakerId: p.Id } },"title":p.Name}},[_c('div',{staticClass:"image-wrapper"},[(p.ITFellow === true)?_c('div',{staticClass:"flag"},[_vm._v(" IT Fellow ")]):_vm._e(),_c('img',{staticClass:"em-c-media-block__img em-c-avatar",staticStyle:{"height":"120px","width":"120px","background-size":"cover","background-position":"center"},style:({
                      backgroundImage: 'url(' + p.PhotoMd + ')',
                    })})]),_c('p',{staticClass:"speaker-headline",domProps:{"innerHTML":_vm._s(p.Name)}}),_c('p',{staticClass:"speaker-team",domProps:{"innerHTML":_vm._s(p.Title)}})])],1)}),0),_c('p'),_c('h3',{staticClass:"em-c-page-header__title title"},[_vm._v("Volunteers")]),_c('ul',{staticClass:"em-c-tile-list em-u-margin-bottom-double em-l-grid em-l-grid--1-2-4up"},_vm._l((_setup.volunteers),function(p,index){return _c('li',{key:p.Id,staticClass:"em-c-tile-list__item em-l-grid__item",attrs:{"p":p,"index":index}},[_c('router-link',{staticClass:"em-c-tile",attrs:{"to":{ name: 'speaker', params: { speakerId: p.Id } },"title":p.Name}},[_c('div',{staticClass:"image-wrapper"},[(p.ITFellow === true)?_c('div',{staticClass:"flag"},[_vm._v(" IT Fellow ")]):_vm._e(),_c('img',{staticClass:"em-c-media-block__img em-c-avatar",staticStyle:{"height":"120px","width":"120px","background-size":"cover","background-position":"center"},style:({
                      backgroundImage: 'url(' + p.PhotoMd + ')',
                    })})]),_c('p',{staticClass:"speaker-headline",domProps:{"innerHTML":_vm._s(p.Name)}}),_c('p',{staticClass:"speaker-team",domProps:{"innerHTML":_vm._s(p.Title)}})])],1)}),0),_c('p'),_c('h3',{staticClass:"em-c-page-header__title title"},[_vm._v("Hosts")]),_c('ul',{staticClass:"em-c-tile-list em-u-margin-bottom-double em-l-grid em-l-grid--1-2-4up"},_vm._l((_setup.hosts),function(p,index){return _c('li',{key:p.Id,staticClass:"em-c-tile-list__item em-l-grid__item",attrs:{"p":p,"index":index}},[_c('router-link',{staticClass:"em-c-tile",attrs:{"to":{ name: 'speaker', params: { speakerId: p.Id } },"title":p.Name}},[_c('div',{staticClass:"image-wrapper"},[(p.ITFellow === true)?_c('div',{staticClass:"flag"},[_vm._v(" IT Fellow ")]):_vm._e(),_c('img',{staticClass:"em-c-media-block__img em-c-avatar",staticStyle:{"height":"120px","width":"120px","background-size":"cover","background-position":"center"},style:({
                      backgroundImage: 'url(' + p.PhotoMd + ')',
                    })})]),_c('p',{staticClass:"speaker-headline",domProps:{"innerHTML":_vm._s(p.Name)}}),_c('p',{staticClass:"speaker-team",domProps:{"innerHTML":_vm._s(p.Title)}})])],1)}),0),_c('p'),_c('h3',{staticClass:"em-c-page-header__title title"},[_vm._v(" Speakers ")]),_c('ul',{staticClass:"em-c-tile-list em-u-margin-bottom-double em-l-grid em-l-grid--1-2-4up"},_vm._l((_setup.speakers),function(p,index){return _c('li',{key:p.Id,staticClass:"em-c-tile-list__item em-l-grid__item",attrs:{"p":p,"index":index}},[_c('router-link',{staticClass:"em-c-tile",attrs:{"to":{ name: 'speaker', params: { speakerId: p.Id } },"title":p.Name}},[_c('div',{staticClass:"image-wrapper"},[(p.ITFellow === true)?_c('div',{staticClass:"flag"},[_vm._v(" IT Fellow ")]):_vm._e(),_c('img',{staticClass:"em-c-media-block__img em-c-avatar",staticStyle:{"height":"120px","width":"120px","background-size":"cover","background-position":"center"},style:({
                      backgroundImage: 'url(' + p.PhotoMd + ')',
                    })})]),_c('p',{staticClass:"speaker-headline",domProps:{"innerHTML":_vm._s(p.Name)}}),_c('p',{staticClass:"speaker-team",domProps:{"innerHTML":_vm._s(p.Title)}})])],1)}),0)])])])]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"id":"scrollTop"}},[_c('i',{staticClass:"icon-chevron-up"})])
}]

export { render, staticRenderFns }