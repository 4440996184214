<template>
  <main class="main bg-white">
    <div class="em-l-container">
      <div class="em-c-container__body">
        <section class="em-c-section section">
          <div class="em-c-page-header">
            <h1 class="em-c-page-header__title title">Team</h1>
            <p class="em-c-page-header__desc">
              Learn more about our team of speakers, sponsors, planning,
              volunteers and hosts by clicking on each person to view their
              biography and session details.
              <router-link :to="{ name: 'sessions' }" class="em-c-text-link">
                View all events here </router-link
              >.
            </p>

            <h3 class="em-c-page-header__title title">
              Sponsors: Chief Architects and Site Technical Leads
            </h3>
            <ul
              class="em-c-tile-list em-u-margin-bottom-double em-l-grid em-l-grid--1-2-4up"
            >
              <li
                class="em-c-tile-list__item em-l-grid__item"
                v-for="(p, index) in sponsors"
                v-bind:key="p.Id"
                v-bind:p="p"
                v-bind:index="index"
              >
                <router-link
                  :to="{ name: 'speaker', params: { speakerId: p.Id } }"
                  class="em-c-tile"
                  v-bind:title="p.Name"
                >
                  <div class="image-wrapper">
                    <div v-if="p.ITFellow === true" class="flag">
                      IT Fellow
                    </div>
                    <img
                      v-bind:style="{
                        backgroundImage: 'url(' + p.PhotoMd + ')',
                      }"
                      style="height:120px;width:120px;background-size:cover;background-position:center;"
                      class="em-c-media-block__img em-c-avatar"
                    />
                  </div>
                  <p class="speaker-headline" v-html="p.Name"></p>
                  <p class="speaker-team" v-html="p.Title"></p>
                </router-link>
              </li>
            </ul>

            <p></p>
            <h3 class="em-c-page-header__title title">Planning Team</h3>
            <ul
              class="em-c-tile-list em-u-margin-bottom-double em-l-grid em-l-grid--1-2-4up"
            >
              <li
                class="em-c-tile-list__item em-l-grid__item"
                v-for="(p, index) in planners"
                v-bind:key="p.Id"
                v-bind:p="p"
                v-bind:index="index"
              >
                <router-link
                  :to="{ name: 'speaker', params: { speakerId: p.Id } }"
                  class="em-c-tile"
                  v-bind:title="p.Name"
                >
                  <div class="image-wrapper">
                    <div v-if="p.ITFellow === true" class="flag">
                      IT Fellow
                    </div>
                    <img
                      v-bind:style="{
                        backgroundImage: 'url(' + p.PhotoMd + ')',
                      }"
                      style="height:120px;width:120px;background-size:cover;background-position:center;"
                      class="em-c-media-block__img em-c-avatar"
                    />
                  </div>
                  <p class="speaker-headline" v-html="p.Name"></p>
                  <p class="speaker-team" v-html="p.Title"></p>
                </router-link>
              </li>
            </ul>

            <p></p>
            <h3 class="em-c-page-header__title title">Volunteers</h3>
            <ul
              class="em-c-tile-list em-u-margin-bottom-double em-l-grid em-l-grid--1-2-4up"
            >
              <li
                class="em-c-tile-list__item em-l-grid__item"
                v-for="(p, index) in volunteers"
                v-bind:key="p.Id"
                v-bind:p="p"
                v-bind:index="index"
              >
                <router-link
                  :to="{ name: 'speaker', params: { speakerId: p.Id } }"
                  class="em-c-tile"
                  v-bind:title="p.Name"
                >
                  <div class="image-wrapper">
                    <div v-if="p.ITFellow === true" class="flag">
                      IT Fellow
                    </div>
                    <img
                      v-bind:style="{
                        backgroundImage: 'url(' + p.PhotoMd + ')',
                      }"
                      style="height:120px;width:120px;background-size:cover;background-position:center;"
                      class="em-c-media-block__img em-c-avatar"
                    />
                  </div>
                  <p class="speaker-headline" v-html="p.Name"></p>
                  <p class="speaker-team" v-html="p.Title"></p>
                </router-link>
              </li>
            </ul>

            <p></p>
            <h3 class="em-c-page-header__title title">Hosts</h3>
            <ul
              class="em-c-tile-list em-u-margin-bottom-double em-l-grid em-l-grid--1-2-4up"
            >
              <li
                class="em-c-tile-list__item em-l-grid__item"
                v-for="(p, index) in hosts"
                v-bind:key="p.Id"
                v-bind:p="p"
                v-bind:index="index"
              >
                <router-link
                  :to="{ name: 'speaker', params: { speakerId: p.Id } }"
                  class="em-c-tile"
                  v-bind:title="p.Name"
                >
                  <div class="image-wrapper">
                    <div v-if="p.ITFellow === true" class="flag">
                      IT Fellow
                    </div>
                    <img
                      v-bind:style="{
                        backgroundImage: 'url(' + p.PhotoMd + ')',
                      }"
                      style="height:120px;width:120px;background-size:cover;background-position:center;"
                      class="em-c-media-block__img em-c-avatar"
                    />
                  </div>
                  <p class="speaker-headline" v-html="p.Name"></p>
                  <p class="speaker-team" v-html="p.Title"></p>
                </router-link>
              </li>
            </ul>

            <p></p>
            <h3 class="em-c-page-header__title title">
              Speakers
            </h3>
            <ul
              class="em-c-tile-list em-u-margin-bottom-double em-l-grid em-l-grid--1-2-4up"
            >
              <li
                class="em-c-tile-list__item em-l-grid__item"
                v-for="(p, index) in speakers"
                v-bind:key="p.Id"
                v-bind:p="p"
                v-bind:index="index"
              >
                <router-link
                  :to="{ name: 'speaker', params: { speakerId: p.Id } }"
                  class="em-c-tile"
                  v-bind:title="p.Name"
                >
                  <div class="image-wrapper">
                    <div v-if="p.ITFellow === true" class="flag">
                      IT Fellow
                    </div>
                    <img
                      v-bind:style="{
                        backgroundImage: 'url(' + p.PhotoMd + ')',
                      }"
                      style="height:120px;width:120px;background-size:cover;background-position:center;"
                      class="em-c-media-block__img em-c-avatar"
                    />
                  </div>
                  <p class="speaker-headline" v-html="p.Name"></p>
                  <p class="speaker-team" v-html="p.Title"></p>
                </router-link>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
    <a id="scrollTop"><i class="icon-chevron-up"></i></a>
  </main>
</template>

<script setup>
import SharePointService from "../services/SharePointService";
import { onMounted, ref } from 'vue';

const myProfileConfiguration = ref([]);
const speakers = ref([]);
const sponsors = ref([]);
const planners = ref([]);
const volunteers = ref([]);
const hosts = ref([]);

onMounted(async () => {
  let config_profiles = await SharePointService.get("ProfileConfiguration");
  config_profiles = config_profiles.filter((item) => item.Active); // Grab only active profiles 

  myProfileConfiguration.value = config_profiles;

  speakers.value = config_profiles.filter((profile) => profile.Speaker);
  sponsors.value = config_profiles.filter((profile) => profile.Sponsor);
  planners.value = config_profiles.filter((profile) => profile.Planning);
  volunteers.value = config_profiles.filter((profile) => profile.Volunteer);
  hosts.value = config_profiles.filter((profile) => profile.Host);
});
</script>
