<template>
  <main class="main bg-white">
    <div class="em-l-container">
      <div class="em-c-container__body">
        <section class="em-c-section section">
          <div class="em-c-page-header profile-container">
            <div v-if="programConfiguration">
              <div class="em-c-container__breadcrumbs breadcrumb-container">
                <ol class="em-c-breadcrumbs" role="navigation">
                  <li class="em-c-breadcrumbs__item breadcrumb-style">
                    <router-link
                      :to="{ name: 'sessions' }"
                      class="em-c-breadcrumbs__link"
                      >Program</router-link
                    >
                  </li>
                  <li class="em-c-breadcrumbs__item breadcrumb-style">
                    <span
                      class="em-c-breadcrumbs__link em-c-breadcrumbs__span"
                      >{{ programConfiguration?.Title }}</span
                    >
                  </li>
                </ol>
              </div>

              <div v-if="programConfiguration" class="event-list">
                <div
                  class="em-l-grid em-l-grid--2up event-grid"
                  style="clear: both"
                >
                  <div class="em-l-grid__item event-photo block">
                    <table>
                      <tr class="speaker-tr">
                        <td
                          v-if="programConfiguration?.Profiles.length > 0"
                          class="speaker-td"
                        >
                          <router-link
                            :to="{
                              name: 'speaker',
                              params: {
                                speakerId: programConfiguration?.Profiles[0].Id,
                              },
                            }"
                            v-bind:title="programConfiguration?.Profiles[0].Name"
                          >
                            <img
                              v-if="programConfiguration?.Profiles.length == 1"
                              v-bind:style="{
                                backgroundImage:
                                  'url(' +
                                  programConfiguration?.Profiles[0].PhotoMd +
                                  ')',
                              }"
                              style="
                                height: 120px;
                                width: 120px;
                                min-height: 120px;
                                min-width: 120px;
                                background-size: cover;
                                background-position: center;
                              "
                              class="em-c-avatar"
                            />
                            <img
                              v-if="programConfiguration?.Profiles.length > 1"
                              v-bind:style="{
                                backgroundImage:
                                  'url(' +
                                  programConfiguration?.Profiles[0].PhotoSm +
                                  ')',
                              }"
                              style="
                                height: 80px;
                                width: 80px;
                                min-height: 80px;
                                min-width: 80px;
                                background-size: cover;
                                background-position: center;
                              "
                              class="em-c-avatar"
                            />
                          </router-link>
                        </td>
                        <td v-if="programConfiguration?.Profiles.length >= 2">
                          <router-link
                            :to="{
                              name: 'speaker',
                              params: {
                                speakerId: programConfiguration?.Profiles[1].Id,
                              },
                            }"
                            v-bind:title="programConfiguration?.Profiles[1].Name"
                          >
                            <img
                              v-bind:style="{
                                backgroundImage:
                                  'url(' +
                                  programConfiguration?.Profiles[1].PhotoSm +
                                  ')',
                              }"
                              style="
                                height: 80px;
                                width: 80px;
                                min-height: 80px;
                                min-width: 80px;
                                background-size: cover;
                                background-position: center;
                              "
                              class="em-c-avatar"
                            />
                          </router-link>
                        </td>
                      </tr>
                      <tr
                        v-if="programConfiguration?.Speakers.length > 2"
                        class="speaker-tr"
                      >
                        <td
                          v-if="programConfiguration?.Profiles.length >= 3"
                          class="speaker-td speaker-td-last"
                        >
                          <router-link
                            :to="{
                              name: 'speaker',
                              params: {
                                speakerId: programConfiguration?.Profiles[2].Id,
                              },
                            }"
                            v-bind:title="programConfiguration?.Profiles[2].Name"
                          >
                            <img
                              v-bind:style="{
                                backgroundImage:
                                  'url(' +
                                  programConfiguration?.Profiles[2].PhotoSm +
                                  ')',
                              }"
                              style="
                                height: 80px;
                                width: 80px;
                                min-height: 80px;
                                min-width: 80px;
                                background-size: cover;
                                background-position: center;
                              "
                              class="em-c-avatar"
                            />
                          </router-link>
                        </td>
                        <td
                          v-if="programConfiguration?.Profiles.length >= 4"
                          class="speaker-td-last"
                        >
                          <router-link
                            :to="{
                              name: 'speaker',
                              params: {
                                speakerId: programConfiguration?.Profiles[3].Id,
                              },
                            }"
                            v-bind:title="programConfiguration?.Profiles[3].Name"
                          >
                            <img
                              v-bind:style="{
                                backgroundImage:
                                  'url(' +
                                  programConfiguration?.Profiles[3].PhotoSm +
                                  ')',
                              }"
                              style="
                                height: 80px;
                                width: 80px;
                                min-height: 80px;
                                min-width: 80px;
                                background-size: cover;
                                background-position: center;
                              "
                              class="em-c-avatar"
                            />
                          </router-link>
                        </td>
                      </tr>

                      <tr
                        v-if="programConfiguration?.Profiles.length > 4"
                        class="speaker-tr"
                      >
                        <td
                          v-if="programConfiguration?.Profiles.length >= 5"
                          class="speaker-td speaker-td-last"
                        >
                          <router-link
                            :to="{
                              name: 'speaker',
                              params: {
                                speakerId: programConfiguration?.Profiles[4].Id,
                              },
                            }"
                            v-bind:title="programConfiguration?.Profiles[4].Name"
                          >
                            <img
                              v-bind:style="{
                                backgroundImage:
                                  'url(' +
                                  programConfiguration?.Profiles[4].PhotoSm +
                                  ')',
                              }"
                              style="
                                height: 80px;
                                width: 80px;
                                min-height: 80px;
                                min-width: 80px;
                                background-size: cover;
                                background-position: center;
                              "
                              class="em-c-avatar"
                            />
                          </router-link>
                        </td>
                        <td
                          v-if="programConfiguration?.Profiles.length >= 6"
                          class="speaker-td-last"
                        >
                          <router-link
                            :to="{
                              name: 'speaker',
                              params: {
                                speakerId: programConfiguration?.Profiles[5].Id,
                              },
                            }"
                            v-bind:title="programConfiguration?.Profiles[5].Name"
                          >
                            <img
                              v-bind:style="{
                                backgroundImage:
                                  'url(' +
                                  programConfiguration?.Profiles[5].PhotoSm +
                                  ')',
                              }"
                              style="
                                height: 80px;
                                width: 80px;
                                min-height: 80px;
                                min-width: 80px;
                                background-size: cover;
                                background-position: center;
                              "
                              class="em-c-avatar"
                            />
                          </router-link>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div
                    class="em-l-grid__item em-l-linelength-container dynamic"
                  >
                    <h3 class="eventName" v-html="programConfiguration?.Title"></h3>

                    <h5
                      v-if="programConfiguration?.LocalDateTime.Same"
                      class="event-date inline middle"
                    >
                      <div class="inline">
                        <span class="event-day">{{
                          programConfiguration?.LocalDateTime.StartDayText
                        }}</span
                        >, {{ programConfiguration?.LocalDateTime.StartTime }} -
                        {{ programConfiguration?.LocalDateTime.EndTime }}
                        <span class="event-timezone">{{
                          programConfiguration?.LocalDateTime.TimeZone
                        }}</span>
                      </div>
                    </h5>
                    <h5 v-else class="event-date inline middle">
                      <div class="inline">
                        <span class="event-day">{{
                          programConfiguration?.LocalDateTime.StartDayText
                        }}</span
                        >, {{ programConfiguration?.LocalDateTime.StartTime }} -
                        <span class="event-day">{{
                          programConfiguration?.LocalDateTime.EndDayText
                        }}</span
                        >, {{ programConfiguration?.LocalDateTime.EndTime }}
                        <span class="event-timezone">{{
                          programConfiguration?.LocalDateTime.TimeZone
                        }}</span>
                      </div>
                    </h5>

                    <h5 class="event-speakers">
                      <span class="event-type">{{ programConfiguration?.Type }}</span>
                      with
                      <span
                        v-for="(sp, index) in programConfiguration?.Profiles"
                        :key="index"
                        ><span
                          v-if="
                            Object.keys(programConfiguration?.Profiles).length > 1 &&
                            index ==
                              Object.keys(programConfiguration?.Profiles).length - 1
                          "
                        >
                          and</span
                        >
                        <router-link
                          :to="{
                            name: 'speaker',
                            params: { speakerId: sp.Id },
                          }"
                          class="em-c-text-link event-speaker"
                        >
                          <span class="event-speaker-name">
                            {{ sp.Name }}</span
                          ></router-link
                        >
                        <span
                          v-if="
                            index !=
                              Object.keys(programConfiguration?.Profiles).length -
                                1 &&
                            index !=
                              Object.keys(programConfiguration?.Profiles).length - 2
                          "
                          >,</span
                        >
                      </span>
                    </h5>

                    <h5 class="event-track">
                      <div class="inline nowrap">
                        <span
                          v-for="(t, index) in programConfiguration?.Tracks"
                          :key="index"
                          class="em-c-tags__item em-js-tags-item"
                        >
                          <a
                            class="em-c-tags__link timezone-tag active"
                            :key="t"
                            >{{ t }}</a
                          >
                        </span>
                      </div>
                      Tracks
                    </h5>

                    <p
                      v-html="programConfiguration?.Description"
                      class="event-description"
                    ></p>

                    <!-- <div
                      class="em-c-alert"
                      v-bind:class="{
                        'em-u-is-hidden': !programConfiguration?.isLinkDisplayed,
                      }"
                      role="alert"
                      id="sessionAlert"
                    >
                      <svg class="em-c-icon em-c-icon--small em-c-alert__icon">
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="images/em-icons.svg#circle-check"
                        ></use>
                      </svg>
                      <div
                        class="em-c-alert__body"
                        style="
                          overflow-wrap: break-word;
                          word-wrap: break-word;
                          word-break: break-word;
                        "
                      >
                        Link copied to clipboard:
                        <span class="alert-link">
                          <router-link
                            :to="{
                              name: 'session',
                              params: { sessionId: programConfiguration?.Id },
                            }"
                          >
                            {{ getSessionLink(programConfiguration?.Id) }}
                          </router-link>
                        </span>
                      </div>
                      <div class="em-c-alert__actions">
                        <a v-on:click="closeSessionLink(programConfiguration)">
                          <svg
                            class="em-c-icon em-c-icon--small em-c-alert__icon alert-close"
                          >
                            <use
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              xlink:href="images/em-icons.svg#x-filled"
                            >
                              <title>close</title>
                            </use>
                          </svg></a
                        >
                      </div>
                    </div> -->

                    <div class="inline">
                      <div
                        v-if="
                          !programConfiguration?.IsSessionInThePast &&
                          programConfiguration?.CalendarLink
                        "
                        class="event-btn block"
                      >
                        <a
                          class="em-c-btn em-c-btn--primary"
                          :href="calendarClick(programConfiguration?.CalendarLink)"
                        >
                          <div class="em-c-btn__inner">
                            <svg
                              class="em-c-icon em-c-icon--small em-c-btn__icon"
                              data-em-icon-path="assets/images/em-icons.svg"
                            >
                              <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="images/em-icons.svg#calendar"
                              ></use>
                            </svg>
                            <span class="em-c-btn__text">Add to calendar</span>
                          </div>
                        </a>
                      </div>
                      <div
                        v-if="
                          !programConfiguration?.IsSessionInThePast &&
                          programConfiguration?.EventXLink
                        "
                        class="event-btn block"
                      >
                        <a
                          class="em-c-btn em-c-btn--primary"
                          :href="calendarClick(programConfiguration?.EventXLink)"
                        >
                          <div class="em-c-btn__inner">
                            <svg
                              class="em-c-icon em-c-icon--small em-c-btn__icon"
                              data-em-icon-path="assets/images/em-icons.svg"
                            >
                              <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="images/em-icons.svg#pencil"
                              ></use>
                            </svg>
                            <span class="em-c-btn__text"
                              >Register (EventXLink)</span
                            >
                          </div>
                        </a>
                      </div>
                      <div
                        class="event-btn block"
                        v-if="programConfiguration?.IsSessionInThePast && programConfiguration?.RecordedSessionLink"
                      >
                        <a
                          class="em-c-btn em-c-btn--primary"
                          target="_blank"
                          :href="programConfiguration?.RecordedSessionLink"
                        >
                          <div class="em-c-btn__inner">
                            <svg
                              class="em-c-icon em-c-icon--small em-c-btn__icon"
                              data-em-icon-path="assets/images/em-icons.svg"
                            >
                              <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="images/em-icons.svg#video-camera"
                              ></use>
                            </svg>
                            <span class="em-c-btn__text">Watch recording</span>
                          </div>
                        </a>
                      </div>

                      <div
                        class="event-btn block"
                        v-if="
                          programConfiguration?.IsSessionInThePast &&
                          !!programConfiguration?.PresentationMaterialLink
                        "
                      >
                        <a
                          class="em-c-btn"
                          target="_blank"
                          :href="programConfiguration?.PresentationMaterialLink"
                        >
                          <div class="em-c-btn__inner">
                            <svg
                              class="em-c-icon em-c-icon--small em-c-btn__icon"
                              data-em-icon-path="assets/images/em-icons.svg"
                            >
                              <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="images/em-icons.svg#file-pptx"
                              ></use>
                            </svg>
                            <span class="em-c-btn__text">View slides</span>
                          </div>
                        </a>
                      </div>

                      <div class="event-btn block">
                        <a
                          class="em-c-btn"
                          v-on:click="shareSession(programConfiguration?.Id)"
                          v-on:click.middle="shareSession(programConfiguration?.Id)"
                        >
                          <div class="em-c-btn__inner">
                            <svg
                              class="em-c-icon em-c-icon--small em-c-btn__icon"
                              data-em-icon-path="assets/images/em-icons.svg"
                            >
                              <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="images/em-icons.svg#share"
                              ></use>
                            </svg>
                            <span class="em-c-btn__text">Share session</span>
                          </div>
                        </a>
                      </div>

                      <div
                        class="event-btn block"
                        v-if="
                          !programConfiguration?.IsSessionInThePast &&
                          programConfiguration?.ShowSurvey
                        "
                      >
                        <a
                          class="em-c-btn"
                          target="_blank"
                          :href="programConfiguration?.SurveyLink"
                        >
                          <div class="em-c-btn__inner">
                            <svg
                              class="em-c-icon em-c-icon--small em-c-btn__icon"
                              data-em-icon-path="assets/images/em-icons.svg"
                            >
                              <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="images/em-icons.svg#envelope-open"
                              ></use>
                            </svg>
                            <span class="em-c-btn__text">Submit Questions</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="em-c-container__breadcrumbs breadcrumb-container">
                <ol class="em-c-breadcrumbs" role="navigation">
                  <li class="em-c-breadcrumbs__item breadcrumb-style">
                    <router-link
                      :to="{ name: 'sessions' }"
                      class="em-c-breadcrumbs__link"
                      >Program</router-link
                    >
                  </li>
                  <li class="em-c-breadcrumbs__item breadcrumb-style">
                    <span class="em-c-breadcrumbs__link em-c-breadcrumbs__span"
                      >Error</span
                    >
                  </li>
                </ol>
              </div>
              <div class="em-u-padding-top-double filter-error">
                <div>
                  <p class="em-c-page-header__desc">
                    <span class="em-u-font-style-semibold filter-red"
                      >No UNITE event matches that ID.
                    </span>
                  </p>
                  <p class="em-c-page-header__desc">
                    Please return to the
                    <router-link
                      :to="{ name: 'sessions' }"
                      class="em-c-text-link"
                      >program page</router-link
                    >
                    to view all sessions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <a id="scrollTop"><i class="icon-chevron-up"></i></a>
  </main>
</template>

<script setup>
import SharePointService from '../services/SharePointService';
import { defineProps, onMounted, ref } from 'vue';
import { setupProgram, shareSession, calendarClick } from '../utils/SessionUtils';

const props = defineProps(["sessionId"]);

const programConfiguration = ref({});

onMounted(async () => {
  if (props.sessionId) {
    let program_config = await SharePointService.getWithFilter("UniteProgramList", `?$filter=Id eq ${props.sessionId}`);
    if (program_config && program_config.length > 0) {
      programConfiguration.value = await setupProgram(program_config[0]);
    }
    else {
      programConfiguration.value = null;
    }
  }
});
</script>
