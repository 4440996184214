<template>
  <section
    id="filterSection"
    class="em-c-section em-c-section--expandable"
    :class="[
      {
        'em-is-closed': !filters.filterOpen,
      },
    ]"
  >
    <header
      class="em-c-section__header filter-header em-js-section-trigger"
      id="filterHeader"
      :class="[
        {
          'filter-open': filters.filterOpen,
        },
      ]"
      v-on:click="toggleFilter()"
    >
      <svg class="em-c-icon em-c-icon--small em-c-accordion__icon">
        <use
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="images/em-icons.svg#caret-down"
        ></use>
      </svg>
      <span class="em-c-accordion__title-text">
        Search events and update time zone preferences.
        <span
          >(<a class="em-c-text-link" @click="resetFilters()"
            >Reset filters</a
          >)</span
        >
      </span>
    </header>
    <!-- Filters -->
    <div class="em-c-section__body em-js-section-target">
      <div class="filter-section">
        <div>
          <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item filter-item search">
              <div>
                <div class="filter-div block">
                  <div>
                    <svg
                      class="em-c-icon em-c-icon--medium filter-svg"
                      data-em-icon-path="images/em-icons.svg"
                    >
                      <use
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xlink:href="images/24/em-icons.svg#search"
                      ></use>
                    </svg>
                  </div>
                </div>
                <div class="em-c-field block" id="search-form">
                  <div class="em-c-search__body">
                    <input
                      type="search"
                      id="searchfield"
                      class="em-c-input em-js-input search-input"
                      placeholder="Search by speaker or event details"
                      value=""
                      v-model="currentFilters.searchFilter"
                    />
                    <a class="em-c-search-med">
                      <svg
                        v-if="currentFilters.searchFilter?.length > 0"
                        class="em-c-icon em-c-icon--medium em-c-btn__icon link-cursor clear-icon"
                        data-em-icon-path="images/em-icons.svg"
                        style="
                          margin-left: -35px;
                          margin-top: 10px;
                          position: relative;
                          z-index: 1;
                        "
                        @click="clearSearch(currentFilters)"
                      >
                        <use
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xlink:href="images/24/em-icons.svg#x-filled"
                        ></use>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="filter-item block">
            <div class="filter-div block">
              <div>
                <svg
                  class="em-c-icon em-c-icon--medium filter-svg-alt"
                  data-em-icon-path="images/em-icons.svg"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="images/24/em-icons.svg#location-arrow"
                  ></use>
                </svg>
              </div>
            </div>
            <div
              class="em-c-dropdown-check em-js-dropdown-check filter-dropdown-padding block"
              :class="[
                {
                  'em-is-active': currentFilters.regionsOpen,
                },
              ]"
            >
              <button
                class="em-c-btn em-c-btn--med em-c-btn--is-dropdown em-js-dropdown-trigger em-c-dropdown-med filter-padding input-style input-width"
                :class="[
                  {
                    'em-is-active': currentFilters.regionsOpen,
                  },
                ]"
                v-on:click="toggleRegionsFilter()"
              >
                <div class="em-c-btn__inner dropdown-select">
                  <span class="em-c-btn__text text-select">Regions</span>
                  <svg
                    class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only icon-select"
                  >
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="images/em-icons.svg#caret-down"
                    ></use>
                  </svg>
                </div>
              </button>
              <div
                class="em-c-dropdown-check__panel input-style check-panel-padding"
              >
                <label
                  class="em-c-input-group em-c-checkbox--container checkbox-padding"
                  for="AM-timezone"
                >
                  <input
                    id="AM-timezone"
                    type="checkbox"
                    name="AM-checkbox"
                    class="em-c-input-group__control"
                    v-model="currentFilters.AMselected"
                  />
                  <span class="em-c-checkbox--checkmark"></span>
                  <span class="em-c-input-group__text">AMERICAS</span>
                </label>
                <label
                  class="em-c-input-group em-c-checkbox--container checkbox-padding"
                  for="AF-timezone"
                >
                  <input
                    id="AF-timezone"
                    type="checkbox"
                    name="AF-checkbox"
                    class="em-c-input-group__control"
                    v-model="currentFilters.AFselected"
                  />
                  <span class="em-c-checkbox--checkmark"></span>
                  <span class="em-c-input-group__text">AFRICA</span>
                </label>
                <label
                  class="em-c-input-group em-c-checkbox--container checkbox-padding"
                  for="AP-timezone"
                >
                  <input
                    id="AP-timezone"
                    type="checkbox"
                    name="AP-checkbox"
                    class="em-c-input-group__control"
                    v-model="currentFilters.APselected"
                  />
                  <span class="em-c-checkbox--checkmark"></span>
                  <span class="em-c-input-group__text">ASIA PACIFIC</span>
                </label>
                <label
                  class="em-c-input-group em-c-checkbox--container"
                  for="EU-timezone"
                >
                  <input
                    id="EU-timezone"
                    type="checkbox"
                    name="EU-checkbox"
                    class="em-c-input-group__control"
                    v-model="currentFilters.EUselected"
                  />
                  <span class="em-c-checkbox--checkmark"></span>
                  <span class="em-c-input-group__text">EUROPE</span>
                </label>
              </div>
            </div>
          </div>
          <div class="filter-item block">
            <div class="filter-div block">
              <div>
                <svg
                  class="em-c-icon em-c-icon--medium filter-svg-alt"
                  data-em-icon-path="images/em-icons.svg"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="images/24/em-icons.svg#calendar"
                  ></use>
                </svg>
              </div>
            </div>
            <div
              class="em-c-dropdown-check em-js-dropdown-check filter-dropdown-padding block"
              :class="[
                {
                  'em-is-active': currentFilters.datesOpen,
                },
              ]"
            >
              <button
                class="em-c-btn em-c-btn--med em-c-btn--is-dropdown em-js-dropdown-trigger em-c-dropdown-med filter-padding input-style input-width"
                :class="[
                  {
                    'em-is-active': currentFilters.datesOpen,
                  },
                ]"
                v-on:click="toggleDatesFilter()"
              >
                <div class="em-c-btn__inner dropdown-select">
                  <span class="em-c-btn__text text-select">
                    Dates
                  </span>
                  <svg
                    class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only icon-select"
                  >
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="images/em-icons.svg#caret-down"
                    ></use>
                  </svg>
                </div>
              </button>
              <div
                class="em-c-dropdown-check__panel input-style check-panel-padding"
              >
                <label
                  v-for="(d, index) in props?.datesFilterOptions"
                  v-bind:key="d.Date"
                  v-bind:index="index"
                  :for="index"
                  class="em-c-input-group em-c-checkbox--container"
                  :class="[
                    {
                      'checkbox-padding':
                        index !=
                        Object.keys(props?.datesFilterOptions).length - 1,
                    },
                  ]"
                >
                  <input
                    type="checkbox"
                    class="em-c-input-group__control"
                    :id="index"
                    v-model="d.Selected"
                    @click="clickDateOption(d)"
                  />
                  <span class="em-c-checkbox--checkmark" :for="index"></span>
                  <span class="em-c-input-group__text">{{ d.Date }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="filter-item block">
            <div class="filter-div block">
              <div>
                <svg
                  class="em-c-icon em-c-icon--medium filter-svg-alt"
                  data-em-icon-path="images/em-icons.svg"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="images/24/em-icons.svg#light-bulb"
                  ></use>
                </svg>
              </div>
            </div>
            <div
              class="em-c-dropdown-check em-js-dropdown-check block"
              :class="[
                {
                  'em-is-active': currentFilters.tracksOpen,
                },
              ]"
            >
              <button
                class="em-c-btn em-c-btn--med em-c-btn--is-dropdown em-js-dropdown-trigger em-c-dropdown-med filter-padding input-style input-width"
                :class="[
                  {
                    'em-is-active': currentFilters.tracksOpen,
                  },
                ]"
                v-on:click="toggleTracksFilter()"
              >
                <div class="em-c-btn__inner dropdown-select">
                  <span class="em-c-btn__text text-select">
                    Tracks
                  </span>
                  <svg
                    class="em-c-icon em-c-icon--small em-c-btn__icon em-c-btn__icon-only icon-select"
                  >
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="images/em-icons.svg#caret-down"
                    ></use>
                  </svg>
                </div>
              </button>

              <div
                class="em-c-dropdown-check__panel input-style check-panel-padding"
              >
                <label
                  v-for="(track, trackIndex) in props?.tracksFilterOptions"
                  v-bind:key="track.name"
                  class="em-c-input-group em-c-checkbox--container"
                  :class="[
                    {
                      'checkbox-padding':
                        trackIndex !=
                        Object.keys(props?.tracksFilterOptions).length - 1,
                    },
                  ]"
                >
                  <input
                    type="checkbox"
                    class="em-c-input-group__control"
                    v-model="track.selected"
                    @click="clickTrackOption(track)"
                  />
                  <span class="em-c-checkbox--checkmark"></span>
                  <span class="em-c-input-group__text">{{ track.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="filter-type-item block" style="white-space: nowrap">
            <div>
              <svg
                class="em-c-icon em-c-icon--medium filter-type-svg-alt"
                data-em-icon-path="images/em-icons.svg"
              >
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="images/24/em-icons.svg#microphone"
                ></use>
              </svg>
              <label
                class="em-c-input-group em-c-checkbox--container filter-type-check block"
                for="keynote-check"
              >
                <input
                  id="keynote-check"
                  type="checkbox"
                  name="keynote"
                  v-model="currentFilters.keynoteSelected"
                  class="em-c-input-group__control"
                />
                <span class="em-c-checkbox--checkmark"></span>
                <span class="em-c-input-group__text">Keynote</span> </label
              ><label
                class="em-c-input-group em-c-checkbox--container filter-type-check block"
                for="panel-check"
              >
                <input
                  id="panel-check"
                  type="checkbox"
                  name="panel"
                  v-model="currentFilters.panelSelected"
                  class="em-c-input-group__control"
                />
                <span class="em-c-checkbox--checkmark"></span>
                <span class="em-c-input-group__text">Panel</span> </label
              ><label
                class="em-c-input-group em-c-checkbox--container filter-type-check block"
                for="showcase-check"
              >
                <input
                  id="showcase-check"
                  type="checkbox"
                  name="showcase"
                  class="em-c-input-group__control"
                  v-model="currentFilters.showcaseSelected"
                />
                <span class="em-c-checkbox--checkmark"></span>
                <span class="em-c-input-group__text">Showcase</span> </label
              ><label
                class="em-c-input-group em-c-checkbox--container filter-type-check block"
                for="cop-check"
              >
                <input
                  id="cop-check"
                  type="checkbox"
                  name="cop"
                  class="em-c-input-group__control"
                  v-model="currentFilters.copSelected"
                />
                <span class="em-c-checkbox--checkmark"></span>
                <span class="em-c-input-group__text">CoP</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref, watch } from "vue";

const props = defineProps({  
  filters: Object,
  tracksFilterOptions: Array,
  datesFilterOptions: Array,
  sessionList: {
    type: Array,
    required: true,
  },
  });

const currentFilters = ref({});

if (props.filters.value) {
  currentFilters.value = props.filters.value;
} else {
  currentFilters.value = {
    filterOpen: true,
    regionsOpen: false,
    tracksOpen: false,
    datesOpen: false,
    searchFilter: "",
    AMselected: true,
    APselected: true,
    EUselected: true,
    AFselected: true,
    keynoteSelected: true,
    panelSelected: true,
    showcaseSelected: true,
    copSelected: true,
    datesFiltered: false,
    typesFiltered: false,
    regionsFiltered: false,
    tracksFiltered: false,
    yearsFiltered: false,
    datesText: "",
    tracksText: "",
    yearsText: "",
  };
}

const emit = defineEmits(["update-items"]);

function clickDateOption(option){
  props.datesFilterOptions.forEach((date) => {
    if (date.Date === option.Date) {
      date.Selected = !date.Selected;
    }
  });
  filterAll();
}
function clickTrackOption(option){
  props.tracksFilterOptions.forEach((track) => {
    if (track.name === option.name) {
      track.selected = !track.selected;
    }
  });
  filterAll();
}

function filterAll() {
  const filteredList = props.sessionList.filter((session) => {
    let searchFilter = currentFilters.value.searchFilter.toLowerCase();
    let AMRegionFilter = currentFilters.value.AMselected && session.Regions.some((region) => region.includes("AMERICAS"));
    let APRegionFilter = currentFilters.value.APselected && session.Regions.some((region) => region.includes("ASIA PACIFIC"));
    let EURegionFilter = currentFilters.value.EUselected && session.Regions.some((region) => region.includes("EUROPE"));
    let AFRegionFilter = currentFilters.value.AFselected && session.Regions.some((region) => region.includes("AFRICA"));

    let keynoteFilter = currentFilters.value.keynoteSelected
      ? session.ProgramType === "Keynote"
      : false;
    let panelFilter = currentFilters.value.panelSelected
      ? session.ProgramType === "Panel"
      : false;
    let showcaseFilter = currentFilters.value.showcaseSelected
      ? session.ProgramType === "Showcase"
      : false;
    let copFilter = currentFilters.value.copSelected
      ? session.ProgramType === "CoP"
      : false;
    let dateFilter = props.datesFilterOptions.some(
      (date) => {
        return date.Selected && session.LocalDateTime.Filter === date.Date
      }
    );
    let trackFilter = props.tracksFilterOptions.some(
      (track) => track.selected && session.Tracks.includes(track.name)
    );

    return (
      (session.Title.toLowerCase().includes(searchFilter) ||
        session.Speakers.some((speaker) =>
          speaker.toLowerCase().includes(searchFilter)
        )) &&
        (dateFilter) &&
        (AMRegionFilter || APRegionFilter || EURegionFilter || AFRegionFilter ) &&
        (keynoteFilter || panelFilter || showcaseFilter || copFilter) &&
        (trackFilter)
    );
  });
  emit("update-items", filteredList);
}

onMounted(() => {
});

//#region Toggle filters
function toggleFilter() {
  currentFilters.value.filterOpen = !currentFilters.value.filterOpen;
}

function toggleRegionsFilter() {
  currentFilters.value.regionsOpen = !currentFilters.value.regionsOpen;
}

function toggleDatesFilter() {
  currentFilters.value.datesOpen = !currentFilters.value.datesOpen;
}

function toggleTracksFilter() {
  currentFilters.value.tracksOpen = !currentFilters.value.tracksOpen;
}
//#endregion

function clearSearch() {
  currentFilters.value.searchFilter = "";
}

function resetTypesFilter() {
  currentFilters.value.keynoteSelected = true;
  currentFilters.value.panelSelected = true;
  currentFilters.value.showcaseSelected = true;
  currentFilters.value.copSelected = true;
}

function resetFilters() {
  currentFilters.value.searchFilter = "";
  currentFilters.value.AMselected = true;
  currentFilters.value.APselected = true;
  currentFilters.value.EUselected = true;
  currentFilters.value.AFselected = true;
  resetTypesFilter();
  props.datesFilterOptions.forEach((date) => {
    date.Selected = true;
  });
  props.tracksFilterOptions.forEach((track) => {
    track.selected = true;
  });
  filterAll();
}

watch(
  () => currentFilters.value.searchFilter,
  () => {
    filterAll();
  }
);

watch(
  () => currentFilters.value.AMselected,
  () => {
    filterAll();
  }
);

watch(
  () => currentFilters.value.APselected,
  () => {
    filterAll();
  }
);

watch(
  () => currentFilters.value.EUselected,
  () => {
    filterAll();
  }
);

watch(
  () => currentFilters.value.keynoteSelected,
  () => {
    filterAll();
  }
);

watch(
  () => currentFilters.value.panelSelected,
  () => {
    filterAll();
  }
);

watch(
  () => currentFilters.value.showcaseSelected,
  () => {
    filterAll();
  }
);

watch(
  () => currentFilters.value.copSelected,
  () => {
    filterAll();
  }
);

//#endregion
</script>
