import jQuery from '../../node_modules/jquery'

var JF = {},
    $ = jQuery.noConflict();
(function ($) {
    "use strict";
    // Predefined global variables
    var $window = $(window),
        $body = $('body');

    // Site functions
    JF.core = {
        functions: function () {
            JF.core.scrollTop();
        },
        scrollTop: function () {
            var $scrollTop = $('#scrollTop');
            if ($scrollTop.length > 0) {
                var scrollOffset = $body.attr('data-offset') || 400;
                if ($window.scrollTop() > scrollOffset) {
                    if ($body.hasClass("frame")) {
                        $scrollTop.css({
                            'bottom': '46px',
                            'opacity': 1,
                            'z-index': 199
                        });
                    } else {
                        $scrollTop.css({
                            'bottom': '26px',
                            'opacity': 1,
                            'z-index': 199
                        });
                    }
                } else {
                    $scrollTop.css({
                        'bottom': '16px',
                        'opacity': 0
                    });
                }
                $scrollTop.off('click').on('click', function () {
                    $('body,html').stop(true).animate({
                        'scrollTop': 0
                    }, 1000, 'easeInOutExpo');
                    return false;
                });
            }
        }
    };
    JF.elements = {
        functions: function () {
            JF.elements.animations();
            JF.elements.counters();
            JF.elements.countdownTimer();
            JF.elements.countdown();
        },
        animations: function () {
            var $animate = $("[data-animate]");
            if ($animate.length > 0) {
                //Check if jQuery Waypoint plugin is loaded
                if (typeof Waypoint === 'undefined') {
                    JF.elements.notification("Warning", "jQuery Waypoint plugin is missing in plugins.js file.", "danger")
                    return true;
                }
                $animate.each(function () {
                    var elem = $(this);
                    elem.addClass("animated");
                    //Plugin Options
                    elem.options = {
                        animation: elem.attr("data-animate") || "fadeIn",
                        delay: elem.attr("data-animate-delay") || 200,
                        direction: ~elem.attr("data-animate").indexOf("Out") ? "back" : "forward",
                        offsetX: elem.attr("data-animate-offsetX") || 0,
                        offsetY: elem.attr("data-animate-offsetY") || -100
                    }
                    //Initializing jQuery Waypoint plugin and passing the options from data animations attributes
                    if (elem.options.direction == "forward") {
                        new Waypoint({
                            element: elem,
                            handler: function () {
                                var t = setTimeout(function () {
                                    elem.addClass(elem.options.animation + " visible");
                                }, elem.options.delay);
                                this.destroy();
                            },
                            offset: '100%'
                        });
                    } else {
                        elem.addClass("visible");
                        elem.on("click", function () {
                            elem.addClass(elem.options.animation);
                            return false;
                        });
                    }
                    //Demo play
                    if (elem.parents('.demo-play-animations').length) {
                        elem.on("click", function () {
                            elem.removeClass(elem.options.animation);
                            var t = setTimeout(function () {
                                elem.addClass(elem.options.animation);
                            }, 50);
                            return false;
                        });
                    }
                });
            }
        },
        counters: function () {
            var $counter = $('.counter');
            if ($counter.length > 0) {
                //Check if countTo plugin is loaded
                if (typeof $.fn.countTo === 'undefined') {
                    JF.elements.notification("Warning", "jQuery countTo plugin is missing in plugins.js file.", "danger")
                    return true;
                }
                //Initializing countTo plugin 
                $counter.each(function () {
                    var elem = $(this),
                        prefix = elem.find("span").attr("data-prefix") || "",
                        suffix = elem.find("span").attr("data-suffix") || "";

                    new Waypoint({
                        element: elem,
                        handler: function () {
                            elem.find('span').countTo({
                                refreshInterval: 2,
                                formatter: function (value, options) {
                                    return String(prefix) + value.toFixed(options.decimals) + String(suffix);
                                },
                            });
                            this.destroy();
                        },
                        offset: '104%'
                    });
                });
            }
        },
        countdownTimer: function () {
            var $countdownTimer = $('.countdown');
            if ($countdownTimer.length > 0) {
                //Check if countdown plugin is loaded
                if (typeof $.fn.countdown === 'undefined') {
                    JF.elements.notification("Warning", "jQuery countdown plugin is missing in plugins.js file.", "danger")
                    return true;
                }
                $('[data-countdown]').each(function () {
                    var $this = $(this),
                        finalDate = $(this).attr('data-countdown');
                    $this.countdown(finalDate, function (event) {
                        $this.html(event.strftime('<div class="countdown-container"><div class="countdown-box"><div class="number">%-D</div><span>Day%!d</span></div>' + '<div class="countdown-box"><div class="number">%H</div><span>Hours</span></div>' + '<div class="countdown-box"><div class="number">%M</div><span>Minutes</span></div>' + '<div class="countdown-box"><div class="number">%S</div><span>Seconds</span></div></div>'));
                    });
                });

            }
        },
        countdown: function () {
            var $countdown = $(".p-countdown");
            if ($countdown.length > 0) {
                $countdown.each(function () {
                    var $elem = $(this),
                        $elemCount = $elem.find(".p-countdown-count"),
                        $elemShow = $elem.find(".p-countdown-show"),
                        $elemSeconds = $elem.attr("data-delay") || 5;
                    $elemCount.find('.count-number').html($elemSeconds);
                    new Waypoint({
                        element: $elem,
                        handler: function () {
                            var interval = setInterval(function () {
                                $elemSeconds--;
                                if ($elemSeconds == 0) {
                                    clearInterval(interval);
                                    $elemCount.fadeOut("slow");
                                    setTimeout(function () {
                                        $elemShow.fadeIn('show');
                                    }, 1000);
                                } else {
                                    $elemCount.find('.count-number').html($elemSeconds);
                                }
                            }, 1000);
                            this.destroy();
                        },
                        offset: '100%'
                    });
                });
            }
        }
    };

    // Load functions on document ready
    $(document).ready(function () {
        JF.core.functions();
        JF.elements.functions();
    });
    // Recall functions on window scroll
    $window.on('scroll', function () {
        JF.core.scrollTop();
    });

})(jQuery);